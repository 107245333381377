import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import {
  FastBackwardOutlined,
  DoubleLeftOutlined,
  LeftOutlined,
  RightOutlined,
  DoubleRightOutlined,
  VerticalRightOutlined,
  VerticalLeftOutlined,
  FastForwardFilled,
  PlayCircleFilled,
  PauseOutlined,
  ForwardOutlined,
  BackwardOutlined,
  UndoOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import './chart.css';
import { Tooltip, Modal, Select, Button, DatePicker, Dropdown, Radio, Input, Space, Checkbox, Spin, Switch } from 'antd';
import body from './img/body.png';
import revertImage from './img/back.png';
import savePrefImage from './img/saving.png';
import ChartSettingsModal from './ChartSettingsModal';
import ChartIcons from './ChartIcons';
import { CustomDropdown } from './Dropdown';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment';
import { globalData } from './globals';
import {
  axisYStrokeStyles,
  timeIntervalSizes,
  axisYStylesHighlight,
  axisXStyleHighlight,
  fittingRectangleStrokeStyle,
  zoomingRectangleFillStyle,
  fillStyle,
  debounce,
  eventY,
  strokeStyle,
  figureHeight,
  speedMultipliers,
  useEventsHistory,
  checkOverlap,
  checkArtifactOverlaping,
  customEventFields,
  useHoveredElement,
  host,
  getRandomDarkColor,
  getBodyRotation,
  saveSignals,
  saveEvent,
  saveArtifact,
  strokeStyle2,
  eventSectors,
  drawPolygonEvent,
  hideEventHoverChart,
  NoYLabelSignals,
  drawEventFigure,
  createEventChart,
  geteventChartData,
  loadStudyData,
  addArtifactActivate,
  addResizers,
  drawArtifactEventFigure,
  moveEvent,
  addArtifactMoving,
  artifactColors,
  getAutoScale,
  checkChartRanges,
  getToken,
  getBodyRotationDirection,
  bodyRotationColor,
  drawPulseTags,
  getLineSteps,
  getReportId,
  downloadEmployeeData,
  generateSearchOptions,
  eventContextMenu,
  customChangeEventTypes,
  setUpdatingEvents,
  getBodyImage,
  loadAirflowCycles,
  loadWakeSignal,
  eventsArtifactOverlaping,
  saveEvents,
  sleepStageSelected,
  sleepStageUnSelected,
  updateWaterMarkText,
  getCrossedArtifacts,
  addSliderArtifactMoving,
  checkSliderArtifact,
  wakeDataColor,
  drawVertLines,
} from './utils';
import { faSpinner, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';

dayjs.extend(customParseFormat);
const lcjs = require('@arction/lcjs');

const {
  lightningChart,
  ColorRGBA,
  SolidFill,
  SolidLine,
  emptyLine,
  emptyFill,
  AxisTickStrategies,
  UIElementBuilders,
  AxisScrollStrategies,
  FontSettings,
  synchronizeAxisIntervals,
  translatePoint,
  ColorHEX,
  Themes,
  emptyTick,
  customTheme,
  PointShape,
  PalettedFill,
  LUT,
  transparentLine,
  UIVisibilityModes,
  UIDraggingModes,
  MarkerBuilders,
  UIBackgrounds,
  transparentFill,
} = lcjs;

const myTheme = customTheme(Themes.lightNew, {
  axisStyle: new SolidLine({ thickness: 0 }),
  dashboardSplitterStyle: new SolidLine({ thickness: 0 }),
});

let lastPolygonFigure = null;
let lastDrawingPolygonFigure = null;
let rowHeight = 0;
let studySignals = [];
let unusedSignals = [];
let allEventTypesList = [];
let timeIntervalString = null;
let tempEvent;
let newEventSize = {};
let autoChartRanges = {};
let eventId = 1;
let minutesNumber = 5;
let bandSize = 300000;
let zoomIntervalGlobal = { start: 0, end: 300000 };
let dateOriginTime = 0;
let dateEndTime = 300000;
let dateOrigin = null;
let artifactId = 1;
let sliderId = 1;
let imageRotation = 0;
let sampleRates = {};
let signalsData = [];
let selectedChartIds = [];
let firstLoad = true;
let signalIdsData = null;
let positionSignal = null;
let viewConfigurationData = {};
let chartsDataUpdated = 0;
let additionalLinesList = [];
let signalPairs = {};
let chartDrawing = true;
let redrawCharts = true;
let xAxisList = [];
let yAxisList = {};
let chartsList = {};
let signalChartsList = {};
let spoLabels = [];
let spoLabelPoints = [];
let minSpo2Value = null;
let zoomBandChart = null;
let zoomId = null;
let zoomIdActual = null;
let manualEventTypes = {};
let chartSignalDrawData = {};
let resizeElements = [];
let firstAxis = null;
let signalLines = {};
let signalsToLoad = [];
let eventSignalIds = {};
let configurationLoading = false;
let lineSteps,
  chartAreasData = {};
let pulseLabels = [];
let pulseLabelsPoints = [];
let airflowLabelsPoints = [];
let timer = null;
let visibleEventPolygons = [];
let visibleEventTextElements = [];
let searchedEventId = 0;
let lsWaterMarks = [];
let eventWasClicked = false;
let shiftPressed = false;
let bodyImage = body;
let draggingOutsideTimer = null;
let currentDrawingSector = null;
let currentDrawingSignalId = null;
let startPoint = null;
let rightDraggingoutside = false;
let currentChartHeight = 0;
let lastZooomUpdate = 0;
let clickedPlayButton = -1;
const maxRate = 10;
const headerHeight = 175;
let allEventsChart = null;

function Charts({ location }) {
  // const history = createBrowserHistory();
  const studyId = location.match?.params?.id || 142;
  const patientId = location.match?.params?.patientId;
  const guid = location.match?.params?.id;
  globalData.studyId = studyId;
  globalData.patientId = patientId;
  globalData.guid = guid;
  const [signalIds, setSignalIds] = useState(null);
  const [studyData, setStudyData] = useState(null);
  const [interval, setTimeInterval] = useState({});
  const [zoomInterval, setZoomInterval] = useState({ start: 0, end: 300000 });
  const [events, setEvents] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [speed, setSpeed] = useState(0);
  const [speedMultiplier, setSpeedMultiplier] = useState(1);
  const [firstChart, setFirstChart] = useState(null);
  const [eventTypes, setEventTypes] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(false);
  const [selectedCharts, setSelectedCharts] = useState([]);
  const [eventEditMode, setEventEditMode] = useState(false);
  const [allChartData, setAllChartData] = useState({});
  const [chartRanges, setChartRanges] = useState({});
  const [chartSizes, setChartSizes] = useState({});
  const [chartSettingsModal, setChartSettingsModal] = useState(false);
  const [searchEvent, setSearchEvent] = useState(null);
  const [selectedSearhEvent, setSelectedSearchEvent] = useState(null);
  const [eventSize, setEventSize] = useState({});
  const [playMode, setPlayMode] = useState('page');
  const [selectedTimeInterval, setSelectedTimeTinterval] = useState(timeIntervalSizes[2]);
  const [eventTop, setEventTop] = useState(eventY);
  const [visibleEvents, setVisibleEvents] = useState(allEventTypesList);
  const [visibleEventTypesSelectorOpen, setVisibleEventTypesSelectorOpen] = useState(false);
  const [artifacts, setArtifactsState] = useState([]);
  const [selectedArtifact, setSelectedArtifact] = useState(null);
  const [zoomSignalId, setZoomSignalId] = useState(834);
  const [showReportRunModal, setShowReportRunModal] = useState(false);
  const [histogramColor, setHistogramColor] = useState('#FF0000');
  const [setDateOriginTime, setHoverElement, hw, hoverElement] = useHoveredElement();
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [reraPercent, setReraPercent] = useState(4);
  const setArtifacts = useCallback(
    (arts) => {
      //redrawCharts = true;
      globalData.eventsUpdated = true;
      //  redrawCharts = true;
      redrawCharts = true;

      setArtifactsState(arts);
      //if (window.artifactDrawing) addArtifactActivate();
      lastPolygonFigure?.dispose();
      try {
        //globalData.drawSliderFunction(arts);
      } catch (e) {
        console.log(e);

        //drawSlider(dashboard, zoomIdActual, signalData, globalData.chartColors[signalId], rowSizeSum, artifacts);
      }
      //     // globalData.drawArtifactFunction(newArtifact, signalId);
      //   } else {
      //     alert('Error occured when adding artifact');
      //   }
      // })();
    },
    [artifacts, events, studyData],
  );

  useEffect(() => {
    globalData.artifacts = artifacts;
  }, [artifacts]);

  useEffect(() => {
    globalData.zoomInterval = zoomInterval;
  }, [zoomInterval]);

  useEffect(() => {
    globalData.visibleEvents = visibleEvents;
  }, [visibleEvents]);

  useEffect(() => {
    globalData.userEmail = localStorage.getItem('email');
    const username = localStorage.getItem('username') || localStorage.getItem('email');
    if (!username) {
      localStorage.setItem('redirectChart', studyId);
      location.history.push('/login');
      window.location.reload();
    } else globalData.username = username;
    window.eventDrawing = true;
  }, [studyId, location]);

  useEffect(() => {
    if (!globalData.eventTopCustom && eventTop && signalIds) {
      globalData.eventTopCustom = {};
      signalIds.forEach((s) => {
        if (eventSectors[s.FullType]) {
          const top = eventTop;
          eventSectors[s.FullType].forEach((sector) => {
            top[sector.type] = (sector.min + sector.max) / 2;
          });
          globalData.eventTopCustom[s.SignalId] = top;
        }
      });
    }
  }, [eventTop, signalIds]);

  const loadPositionSignal = useCallback(() => {
    const signal = Object.values(globalData.loadedChartsData).find((s) => s.Type === 'Position');
    if (signal) {
      positionSignal = {
        data: signal.data,
        rate: signal.rate,
      };
      globalData.positionSignal = true;
    }
  }, []);

  const afterSignalLoad = useCallback(
    (data) => {
      setDataLoading(false);
      const smallData = {};
      data.forEach((signal, i) => {
        const signalObject = signalIdsData[i];
        const rate = signal.SampleRate;
        const chartData = {
          i,
          SignalId: signalObject.SignalId,
          data: signal.Points.map((point, i) => ({
            x: (i * 1000) / rate,
            y: point,
          })),
          Type: signalObject.Type,
          name: signalObject.Type + (signalObject.Specification ? ' ' + signalObject.Specification : ''),
          Specification: signalObject.Specification,
          rate: signal.SampleRate,
        };
        if (signalObject.Type === 'SPO2' && !minSpo2Value) {
          let min = 80;
          chartData.data.forEach((p) => {
            if (p.y < min && p.y > 75) min = p.y;
          });
          minSpo2Value = Math.floor(min / 10) * 10;
          spoLabels = spoLabels.concat(getMinMaxValues(chartData.data));

          minSpo2Value = minSpo2Value < 75 ? 75 : minSpo2Value;
          if (zoomBandChart) zoomBandChart.getDefaultAxisY().setMouseInteractions(true).setInterval(minSpo2Value, 100, true, true);
        }

        if (signalObject.Type === 'Pulse') {
          pulseLabels = pulseLabels.concat(getMinMaxValues(chartData.data));
        }
        //if (chartData.Type === "Pulse") chartData.data = interpolatePoints(chartData.data);

        const signalId = signalObject.SignalId;
        signalsData[i] = chartData; //.sort((a, b) => a.i - b.i);
        globalData.loadedChartsData[signalId] = chartData;
        smallData[signalId] = {
          SignalId: signalId,
          name: globalData.loadedChartsData[signalId].name,
          Type: globalData.loadedChartsData[signalId].Type,
        };
      });
      [lineSteps, chartAreasData] = getLineSteps(globalData.loadedEventsData);
      loadPositionSignal();
      setAllChartData(smallData);
      checkChartRanges(setChartRanges);
    },
    [loadPositionSignal, setChartRanges],
  );

  const loadSignalsData = useCallback(
    (interval) => {
      if (!studySignals.length || dataLoading) return;
      var myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + globalData.token);
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      const data = [];
      let l = signalIdsData.length;
      signalIdsData.forEach((signal, i) => {
        const rate = sampleRates[signal.SignalId];
        const storageData = localStorage.getItem('study' + studyId + '||' + signal.SignalId + '||' + rate);
        if (storageData) {
          data[i] = JSON.parse(storageData);
          l--;
          if (l === 0) {
            afterSignalLoad(data);
          }
          return;
        }
        let rate2 = 1;
        if (rate2 !== rate) {
          rate2 = rate;

          signalsToLoad.push({
            rate,
            signalId: signal.SignalId,
            i,
          });
          const storageData = localStorage.getItem('study' + studyId + '||' + signal.SignalId + '||' + rate2);
          if (storageData) {
            data[i] = JSON.parse(storageData);
            l--;
            if (l === 0) {
              afterSignalLoad(data);
            }
            return;
          }
        }

        fetch(
          `${host}/sleepstudy/api/signalassinglesegment?SampleRate=${rate2}&signalId=${signal.SignalId}&startTime=${interval.StartTime}&endTime=${interval.EndTime}`,
          requestOptions,
        )
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            l--;
            try {
              window.localStorage.setItem('study' + studyId + '||' + signal.SignalId + '||' + rate2, JSON.stringify(result));
            } catch {}
            data[i] = result;
            if (l === 0) {
              afterSignalLoad(data);
            }
          });
      });
    },
    [dataLoading, afterSignalLoad, studyId],
  );

  const loadEvents = useCallback(
    (interval) => {
      if (!studySignals.length || dataLoading) return;
      let l = studySignals.length;
      const data = {};
      const artifacts = [];
      const additionalEventTypes = [];
      let preciseEventsList = [];
      const eventsTyped = {};
      // studySignals.push({
      //     SignalId: 14517,
      //     Type: "WakeSleep"
      // });
      studySignals.forEach((signal, i) => {
        manualEventTypes[signal.SignalId] = [];
        if (globalData.loadedEventsData[signal.SignalId]) {
          data[signal.SignalId] = globalData.loadedEventsData[signal.SignalId];
          l--;
          if (l < 1) setEvents(data);
          return;
        }
        // if (
        //   !['Airflow', 'SPO2', 'Pulse', 'Snore', 'Pleth'].includes(signal.Type)
        // ) {
        //   globalData.loadedEventsData[signal.SignalId] = [];
        //   data[signal.SignalId] = [];
        //   l--;
        //   return;
        // }
        try {
          var myHeaders = new Headers();
          myHeaders.append('Authorization', 'Bearer ' + globalData.token);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
          };
          fetch(
            `${host}/sleepstudy/api/signalsleepevents?signalId=${signal.SignalId}&startTime=${interval.StartTime}&endTime=${interval.EndTime}`,
            requestOptions,
          )
            .catch(function (error) {
              // catch
              globalData.loadedEventsData[signal.SignalId] = [];
              data[signal.SignalId] = [];
              l--;
            })
            .then((response) => (response.ok ? response.json() : []))
            .then((result) => {
              l--;
              const eventData = [];
              // try {
              //   window.localStorage.setItem("events" + studyId + "|" + signal.SignalId, JSON.stringify(result));
              // } catch {}
              if (result.length) eventSignalIds[signal.SignalId] = [];

              result.forEach((currentValue) => {
                currentValue.Type = currentValue.Type.toString();
                if (currentValue.Type.includes('Artifact')) {
                  artifacts.push({
                    start: new Date(currentValue.StartTime).getTime() - dateOriginTime,
                    end: new Date(currentValue.EndTime).getTime() - dateOriginTime,
                    signalId: currentValue.SignalId,
                    status: currentValue.IsAutoScored ? 'AutoScored' : 'Added',
                    id: artifactId,
                    ...currentValue,
                  });
                  artifactId++;
                  return;
                }
                const id = eventId;
                eventId++;
                if (!preciseEventsList.includes(currentValue.Type)) {
                  preciseEventsList.push(currentValue.Type);
                }
                if (!allEventTypesList.includes(currentValue.Type)) {
                  additionalEventTypes.push(currentValue.Type);
                  allEventTypesList.push(currentValue.Type);
                  globalData.eventColors[currentValue.Type] = getRandomDarkColor();
                }
                if (!manualEventTypes[signal.SignalId].includes(currentValue.Type))
                  manualEventTypes[signal.SignalId].push(currentValue.Type);
                if (!eventSignalIds[signal.SignalId].includes(currentValue.Type)) eventSignalIds[signal.SignalId].push(currentValue.Type);

                const startTimeMicro = new Date(currentValue.StartTime).getTime() - dateOriginTime;
                const endTimeMicro = new Date(currentValue.EndTime).getTime() - dateOriginTime;
                const searchName =
                  currentValue.Type === 'Recovery' || currentValue.Type === 'Desaturation'
                    ? (signal.Type === 'Pulse' ? 'Pulse ' : 'Oximetry ') + currentValue.Type
                    : currentValue.Type;
                eventData.push({
                  status: currentValue.IsAutoScored ? 'AutoScored' : 'Added',
                  type: currentValue.Type.startsWith('Oximetry') ? currentValue.Type : currentValue.Type,
                  customCharacteristics: currentValue.CustomCharacteristics,
                  startTimeMicro,
                  endTimeMicro,
                  searchName,
                  customName:
                    signal.SignalId === globalData.pulseSignalId &&
                    (currentValue.Type === 'Recovery' || currentValue.Type === 'Desaturation') &&
                    currentValue.CustomCharacteristics['DPAR Event Type']
                      ? currentValue.CustomCharacteristics['DPAR Event Type']
                      : undefined,
                  ...currentValue,
                  id,
                });
                if (!eventsTyped[searchName]) eventsTyped[searchName] = [];
                eventsTyped[searchName].push({
                  startTimeMicro,
                  endTimeMicro,
                  id,
                });
                if (
                  signal.SignalId === globalData.spoSignalId &&
                  ['Desaturation', 'Recovery', 'CandidateEvent'].includes(currentValue.Type)
                ) {
                  spoLabels.push(startTimeMicro);
                  spoLabels.push(endTimeMicro);
                }
                if (signal.SignalId === globalData.pulseSignalId) {
                  // pulseLabels.push(startTimeMicro);
                  //  pulseLabels.push(endTimeMicro);
                }
              });

              // console.log(signal);
              // if (signal.Type === 'Airflow' || signal.Type === 'Snore') {
              //   getMaxValuesLine(signal.SignalId).then((data) => {
              //     if (data) {
              //       globalData.maxValuesLines[signal.SignalId] = Object.keys(
              //         data,
              //       ).map((d) => ({
              //         x: new Date(d).getTime() - globalData.dateOriginTime,
              //         y: data[d],
              //       }));
              //       redrawCharts = true;
              //     }
              //   });
              //   getMinValuesLine(signal.SignalId).then((data) => {
              //     if (data) {
              //       globalData.minValuesLines[signal.SignalId] = Object.keys(
              //         data,
              //       ).map((d) => ({
              //         x: new Date(d).getTime() - globalData.dateOriginTime,
              //         y: data[d],
              //       }));
              //       redrawCharts = true;
              //     }
              //   });
              // }
              globalData.loadedEventsData[signal.SignalId] = eventData;
              data[signal.SignalId] = eventData;

              if (l < 1) {
                // console.log('precise', preciseEventsList);
                // allEventTypesList = preciseEventsList;
                // globalData.allEventTypesList = allEventTypesList;
                if (additionalEventTypes.length) {
                  setVisibleEvents((e) => e.concat(additionalEventTypes));
                }
                [lineSteps, chartAreasData] = getLineSteps(globalData.loadedEventsData);
                setArtifactsState(checkSliderArtifact(artifacts));
                setEvents(data);
                Object.keys(eventsTyped).forEach((key) => {
                  eventsTyped[key].sort((a, b) => a.startTimeMicro - b.startTimeMicro);
                });
                globalData.eventsTyped = eventsTyped;
                globalData.searchOptions = generateSearchOptions(eventsTyped);
                if (globalData.pulseSignalId) manualEventTypes[globalData.pulseSignalId] = customChangeEventTypes['Pulse'];
                setEventTypes(manualEventTypes);

                let updateTop = false;
                allEventTypesList.forEach((type) => {
                  if (!globalData.eventTop[type]) {
                    globalData.eventTop[type] = 0.5 + (Math.random() - 0.5) / 2;
                    updateTop = true;
                  }
                });
                if (updateTop) setEventTop(globalData.eventTop);
              }
            });
        } catch {
          l--;
          globalData.loadedEventsData[signal.SignalId] = [];
          data[signal.SignalId] = [];
        }
      });
    },
    [dataLoading],
  );

  const getStudyInterval = useCallback(
    (studySignals) => {
      if (studySignals.length) {
        if (timeIntervalString) {
          return;
        }
        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + globalData.token);
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
        };
        fetch(`${host}/sleepstudy/api/sleepstudy?key=${studyId}`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            timeIntervalString = result;
            const newInterval = {
              start: new Date(result.StartTime),
              end: new Date(result.EndTime),
            };
            dateOriginTime = newInterval.start.getTime();
            dateEndTime = newInterval.end.getTime();
            dateOrigin = newInterval.start;
            globalData.dateOriginTime = dateOriginTime;
            globalData.dateEndTime = dateEndTime;
            globalData.dateEndTimeDiff = dateEndTime - dateOriginTime;

            setTimeInterval(newInterval);
            setDateOriginTime(newInterval.start.getTime());
            loadEvents(result);
            loadSignalsData(result);
            createEventChart();
          });
      }
    },
    [loadSignalsData, loadEvents, studyId, setDateOriginTime],
  );

  const loadStudySignals = useCallback(
    (allSignals) => {
      if (signalIds) {
        unusedSignals = [...Array(signalIds.length).keys()].filter((i) => !selectedCharts.includes(i));
        studySignals = selectedCharts.map((i) => signalIds[i] || null).filter((s) => s);
        return;
      }
      var myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + globalData.token);
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(`${host}/sleepstudy/api/sleepstudysignals?key=${studyId}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          const ids = [];
          result.forEach((r, i) => {
            if (r.Type === 'WakeSleep') {
              loadWakeSignal(r);
              globalData.wakeSignalData = r;
              //ids.push(r.SignalId);
              return;
            }

            ids.push(r.SignalId);
            sampleRates[r.SignalId] = Math.min(r.OriginalSampleRate || 10, maxRate);
            if (r.Type === 'Snore') sampleRates[r.SignalId] = 25;
            if (!signalPairs[r.SignalId]) {
              const s1 = r.Specification ? r.Specification + ' ' : '';
              const pair = result.find(
                (s) => s.SignalId !== r.SignalId && s.Type === r.Type && s.Specification === s1 + 'Transformed (Type 2)',
              );
              if (pair) {
                signalPairs[r.SignalId] = pair.SignalId;
                signalPairs[pair.SignalId] = r.SignalId;
              }
            }
            //if (r.Type === "Pulse" || r.Type === "SPO2") globalData.gridLines[r.SignalId] = true;
            // if (r.Type === "Pulse") {
            //   getPulseTags(r.SignalId).then((data) => {
            //     if (data && data.length) {
            //       globalData.pulseTags = data.map((t) => ({
            //         start: new Date(t.StartTime).getTime() - globalData.dateOriginTime,
            //         end: new Date(t.EndTime).getTime() - globalData.dateOriginTime,
            //         ClusterType: t.ClusterType,
            //       }));
            //       //redrawCharts = true;
            //     }
            //   });
            // }
            if (r.Type === 'Airflow') {
              globalData.AirflowSignalId = r.SignalId;
              loadAirflowCycles(r.SignalId);
            }

            result[i].FullType = r.Type + r.Specification;
            globalData.signalIcon[r.SignalId] = globalData.signalIcon[r.Type];
            if (!globalData.signalIcon[r.Type] && r.Type.includes('Artifact')) {
              globalData.signalIcon[r.SignalId] = globalData.signalIcon['Artifact'];
            }
          });
          result = result.filter((r) => allSignals.includes(r.SignalId) && !r.Specification);

          selectedChartIds = selectedChartIds.filter((id) => ids.includes(id));

          signalIdsData = result;
          const selected = selectedCharts.length ? selectedCharts : selectedChartIds.map((i) => result.findIndex((r) => r.SignalId === i));
          setSelectedCharts(selected);
          setSignalIds(result);
          studySignals = selectedChartIds.map((id) => result.find((s) => s.SignalId === id));
          unusedSignals = [...Array(result.length).keys()].filter((i) => !selected.includes(i));
          getStudyInterval(studySignals);
        });
    },
    [selectedCharts, signalIds, studyId, getStudyInterval],
  );

  const loadViewConfiguration = useCallback(
    async (studyId) => {
      var myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + globalData.token);
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(`${host}/sleepstudy/api/sleepstudyviewconfiguration?key=${studyId}&username=${globalData.userEmail}`, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          viewConfigurationData = result;
          const selected = [];
          const h = 1 / result.SlotConfigurations.length;
          const sizes = {};
          let seriesNames = {};
          result.SlotConfigurations.forEach((conf) => {
            const chart = result.ChartConfigurations.find((c) => c.SlotReference === conf.SlotConfigurationId);
            if (chart) {
              const series = result.SeriesConfigurations.filter(
                (s) => s.ChartReference === chart.ChartConfigurationId && s.ChartReference !== viewConfigurationData.HistogramId,
              );
              selected.push(series[0].SignalId);
              seriesNames[series[0].SignalId] = chart.Caption;
              globalData.gridLines[series[0].SignalId] = conf.AreGridlinesVisible;
              globalData.showValues[series[0].SignalId] = conf.ShowValues;
              if (series.length > 1) {
                // globalData.additionalLines[series[0].SignalId] = series[1].SignalId;
                // additionalLinesList = additionalLinesList.concat(series.slice(1).map((s) => s.SignalId));
              }
              sizes[series[0].SignalId] = conf.HeightPercentage;
            }
          });

          if (result.EventConfigurations) {
            if (
              result.EventConfigurations.find((e) => e.Type === 'OximetryReciprocation') &&
              !result.EventConfigurations.find((e) => e.Type === 'PulseReciprocation')
            ) {
              const pulseEvent = result.EventConfigurations.find((e) => e.Type === 'OximetryReciprocation');
              result.EventConfigurations.push({
                ...pulseEvent,
                Type: 'PulseReciprocation',
              });
            }
            const eventPos = { ...eventTop };
            result.EventConfigurations.forEach((evConf) => {
              eventPos[evConf.Type] = 1 - evConf.VerticalPosition;
            });
            globalData.eventTop = eventPos;
            setEventTop(eventPos);
            allEventTypesList = result.EventConfigurations.map((e) => e.Type);
            globalData.allEventTypesList = allEventTypesList;
            allEventTypesList.forEach((key) => {
              if (!globalData.eventColors[key]) globalData.eventColors[key] = getRandomDarkColor();
            });

            const visibleEventsTemp = result.EventConfigurations.filter((e) => e.Visible).map((e) => e.Type);
            setVisibleEvents(visibleEventsTemp);
            // globalData.firstChartEvents = allEventTypesList.map((type) => ({ type, show: visibleEventsTemp.includes(type) }));
          }
          const colors = { ...globalData.chartColors };
          const allSignals = [];
          if (result.SeriesConfigurations) {
            const ranges = { ...chartRanges };
            result.SeriesConfigurations.forEach((series) => {
              if (result.HistogramId === series.ChartReference) {
                setHistogramColor(series.StrokeColor);
                return;
              }
              allSignals.push(series.SignalId);
              colors[series.SignalId] = series.StrokeColor;
              if (colors[series.SignalId].length === 9 && colors[series.SignalId].endsWith('00')) {
                colors[series.SignalId] = colors[series.SignalId].slice(0, 7);
              }
              ranges[series.SignalId] = {
                min: series.DefaultValueBottom,
                max: series.DefaultValueTop,
              };
              if (seriesNames[series.SignalId] === 'Pulse') globalData.pulseSignalId = series.SignalId;
              if (seriesNames[series.SignalId] === 'SPO2') globalData.spoSignalId = series.SignalId;
              if (seriesNames[series.SignalId] === 'Airflow') globalData.airflowSignalId = series.SignalId;
              if (!sizes[series.SignalId]) sizes[series.SignalId] = 1;
            });
            setChartRanges(ranges);
            globalData.initialRanges = ranges;
            globalData.chartRanges = ranges;
            globalData.chartColors = colors;
          }
          setPlayMode(result.ViewMode || 'Page');
          // sizes[1206] = 2;
          // sizes[1209] = 2;
          setChartSizes(sizes);
          setSpeedMultiplier(result.ScrollSpeed || 1);

          selectedChartIds = selected;
          const zoomSignal = result.SeriesConfigurations.find((s) => s.ChartReference === result.HistogramId);
          if (zoomSignal && selectedChartIds.includes(zoomSignal.SignalId)) {
            setZoomSignalId(zoomSignal.SignalId);
            setHistogramColor(colors[zoomSignal.SignalId]);
            zoomId = zoomSignal.SignalId;
          } else {
            setZoomSignalId(selectedChartIds[0]);
            setHistogramColor(colors[selectedChartIds[0]]);
            zoomId = selectedChartIds[0];
          }
          loadStudySignals(allSignals);
        });
    },
    [loadStudySignals, chartRanges, eventTop],
  );

  const updateChartsOrder = useCallback(
    (charts) => {
      studySignals = charts.map((i) => signalIds[i] || null).filter((s) => s);
      unusedSignals = [...Array(signalIds.length).keys()].filter((i) => !charts.includes(i));
      selectedChartIds = studySignals.map((s) => s.SignalId);
      redrawCharts = true;
      setSelectedCharts(charts.slice(0));
      chartsDataUpdated = chartsDataUpdated + 1;
    },
    [setSelectedCharts, signalIds],
  );

  const setChartsSettings = useCallback(
    (chartsSettings) => {
      const newChartColors = { ...globalData.chartColors };
      const newChartRanges = { ...chartRanges };
      const signalId = chartsSettings.signalId;
      if (signalId) {
        if (chartsSettings.color) {
          newChartColors[signalId] = chartsSettings.color;
          globalData.chartColors = newChartColors;
        }
        if (chartsSettings.range) {
          newChartColors[signalId] = chartsSettings.range;
          setChartRanges(newChartRanges);
          globalData.chartRanges = newChartRanges;
        }
      }
      if (chartsSettings.charts) updateChartsOrder(chartsSettings.charts);
    },
    [chartRanges, updateChartsOrder],
  );

  const setEventsFromHistory = useCallback(
    (data, signalId) => {
      redrawCharts = true;
      globalData.loadedEventsData[signalId] = data;
      globalData.eventsUpdated = true;
      setEvents((events) => {
        return { ...events, [signalId]: data };
      });
    },
    [setEvents],
  );

  const [undoEvent, redoEvent, undoActive, redoActive, titles, removeHistory, addHistoryEvent] = useEventsHistory(
    setEventsFromHistory,
    updateChartsOrder,
    setChartsSettings,
    setVisibleEvents,
    setArtifacts,
  );
  //const addHistoryEvent = globalData.addHistoryEvent;

  let dashboardObject = useRef(null);
  let moveInterval = useRef(null);

  const deleteEvents = useCallback(
    (selectedEvents) => {
      if (!selectedEvents?.length) return;
      const deletedEvents = [];

      selectedEvents.forEach((selectedEvent) => {
        const signalId = selectedEvents[0].signalId;
        const newEvents = globalData.loadedEventsData[signalId].slice(0);
        const id = selectedEvent.id;
        const type = selectedEvent.text;
        if (!type || !globalData.allEventsList[type]) return;
        const i = newEvents.findIndex((e) => e.id === id);
        newEvents[i] = { ...newEvents[i], removed: true };
        deletedEvents.push(newEvents[i]);
        const fo = globalData.allEventsList[type].filter((e) => e.id === id);
        if (fo.length) {
          fo[0].figure?.dispose();
          fo[0].textFigure?.dispose();
          fo[0].resizeElementBack?.dispose();
          fo[0].resizeElementFront?.dispose();
        }
        globalData.allEventsList[type] = globalData.allEventsList[type].filter((e) => e.id !== id);

        if (signalId === globalData.pulseSignalId && type === 'RERA' && globalData.allEventsList['RDI_RERA']) {
          const start = newEvents[i].startTimeMicro - 50000;
          const end = newEvents[i].endTimeMicro;
          const connectedEvents = globalData.allEventsList['RDI_RERA'].filter((ev) => {
            return (ev.start >= start && ev.start <= end) || (ev.end >= start && ev.end <= end);
          });
          if (connectedEvents.length) {
            const sigId = connectedEvents[0].signalId;
            const newEvents2 = globalData.loadedEventsData[sigId].slice(0);
            connectedEvents.forEach((ev) => {
              const j = newEvents2.findIndex((e) => e.id === ev.id);
              newEvents2[j] = { ...newEvents2[j], removed: true };
              deletedEvents.push(newEvents2[j]);
              const fo = globalData.allEventsList['RDI_RERA'].filter((e) => e.id === ev.id);
              if (fo.length) {
                fo[0].figure?.dispose();
                fo[0].textFigure?.dispose();
                fo[0].resizeElementBack?.dispose();
                fo[0].resizeElementFront?.dispose();
              }
              globalData.allEventsList['RDI_RERA'] = globalData.allEventsList['RDI_RERA'].filter((e) => e.id !== ev.id);
            });
            globalData.loadedEventsData[sigId] = newEvents2;
            //newEvents1[sigId] = newEvents2;
          }
        }
        if (signalId === globalData.spoSignalId && globalData.airflowSignalId && type === 'Desaturation') {
          const start = newEvents[i].startTimeMicro - 50000;
          const end = newEvents[i].endTimeMicro;
          const newEvents2 = globalData.loadedEventsData[globalData.airflowSignalId].slice(0);
          newEvents2.forEach((ev, j) => {
            if ((ev.startTimeMicro >= start && ev.startTimeMicro <= end) || (ev.endTimeMicro >= start && ev.endTimeMicro <= end)) {
              if (!globalData.allEventsList[ev.type]) {
                return;
              }
              newEvents2[j] = { ...newEvents2[j], removed: true };
              deletedEvents.push(newEvents2[j]);
              const fo = globalData.allEventsList[ev.type].filter((e) => e.id === ev.id);
              if (fo.length) {
                fo[0].figure?.dispose();
                fo[0].textFigure?.dispose();
                fo[0].resizeElementBack?.dispose();
                fo[0].resizeElementFront?.dispose();
              }
              globalData.allEventsList[ev.type] = globalData.allEventsList[ev.type].filter((e) => e.id !== ev.id);
            }
          });
          globalData.loadedEventsData[globalData.airflowSignalId] = newEvents2;
          //newEvents1[globalData.airflowSignalId] = newEvents2;
        }
        globalData.loadedEventsData[signalId] = newEvents;
      });
      globalData.eventsUpdated = true;
      setEvents(globalData.loadedEventsData);

      newEventSize = {};
      setModalOpen(false);
      setSelectedEvent(null);
      globalData.eventPressed = null;
      saveEvents(deletedEvents);
    },
    [selectedEvent],
  );

  const deleteAllEventOfType = useCallback(
    (type, signalId) => {
      const events1 = globalData.loadedEventsData[signalId]
        .filter((e) => {
          if (e.type === type) {
            return true;
          }
          return false;
        })
        .map((event) => ({
          id: event.id,
          signalId: event.SignalId,
          text: event.type,
        }));
      deleteEvents(events1);
      const i = globalData.firstChartEvents.findIndex((t) => t.title === type || t.value === type);
      if (i >= 0) {
        globalData.firstChartEvents[i].show = false;
        globalData.firstChartEvents[i].hiddenTemp = true;
        drawAllEventsChart();
      }
    },
    [deleteEvents],
  );

  useEffect(() => {
    globalData.deleteEvents = deleteEvents;
  }, [deleteEvents]);

  const addArtifact = useCallback(
    (start, end, signalId) => {
      const date = moment().format('DD.MM.Y hh:mm:ss');
      const newArtifact = {
        start,
        end,
        signalId,
        id: artifactId,
        added: true,
        Type: 'ExcludedArtifact',
        status: `Added by ${globalData.username} on ${date}`,
      };
      artifactId++;
      const crossedArtifact = checkArtifactOverlaping(start, end, newArtifact.signalId, null);
      if (crossedArtifact) {
        alert('Artifact cannot overlap another Artifact');
        lastPolygonFigure?.dispose();
        lastDrawingPolygonFigure?.dispose();
        return;
      }

      const crossedEvents = eventsArtifactOverlaping(newArtifact);
      deleteEvents(crossedEvents);
      // if (crossedEvent) {
      //   alert(crossedEvent.type + ' events cannot overlap Excluded Artifact');
      //   lastDrawingPolygonFigure?.dispose();
      //   lastPolygonFigure?.dispose();
      //   return;
      // }
      const arts = globalData.artifacts.slice(0);
      (async () => {
        const res = (await saveArtifact(newArtifact))[0];

        if (res?.SleepEventId) {
          newArtifact.SleepEventId = res.SleepEventId;
          arts.push(newArtifact);
          globalData.drawArtifactFunction[signalId](newArtifact);
          globalData.artifacts = arts;
          setArtifacts(arts);
        }
      })();
    },
    [setArtifacts, artifacts, deleteEvents, addHistoryEvent],
  );
  //console.log({ artifactId, artifacts });

  const addSliderArtifact = useCallback(
    (start, end) => {
      let crossedArtifacts = [];
      let allCrossedEvents = [];
      const newArtifacts = [];
      let c = signalIds.length;
      const arts = globalData.artifacts.slice(0);
      signalIds.forEach((s) => {
        const date = moment().format('DD.MM.Y hh:mm:ss');
        newArtifacts.push({
          start,
          end,
          signalId: s.SignalId,
          id: artifactId,
          added: true,
          Type: 'ExcludedArtifact',
          status: `Added by ${globalData.username} on ${date}`,
        });

        //return;
        artifactId++;
        const crossedArtifact = getCrossedArtifacts(start, end, s.SignalId, null);
        if (crossedArtifact?.length) {
          crossedArtifacts = crossedArtifacts.concat(crossedArtifact);
        }

        const crossedEvents = eventsArtifactOverlaping(newArtifacts[newArtifacts.length - 1]);
        allCrossedEvents = allCrossedEvents.concat(crossedEvents);
      });
      deleteEvents(allCrossedEvents);

      (async () => {
        if (crossedArtifacts?.length) {
          await saveArtifact(crossedArtifacts.map((a) => ({ ...a, removed: true })));
          crossedArtifacts.forEach((artifact) => {
            const id = artifact.id;
            if (globalData.artifactsList[id]) {
              globalData.artifactsList[id].resizeElementFront?.dispose();
              globalData.artifactsList[id].resizeElementBack?.dispose();
              globalData.artifactsList[id].figure?.dispose();
            }
            const i = arts.findIndex((t) => t.id === id);
            if (i >= 0) arts[i].removed = true;
          });
        }
        const res = await saveArtifact(newArtifacts);
        sliderId++;
        res.forEach((newArtifact, i) => {
          if (newArtifact?.SleepEventId) {
            newArtifact.id = newArtifacts[i].id;
            newArtifact.signalId = newArtifacts[i].signalId;
            newArtifact.start = new Date(newArtifact.StartTime).getTime() - dateOriginTime;
            newArtifact.end = new Date(newArtifact.EndTime).getTime() - dateOriginTime;
            newArtifact.sliderId = sliderId;
            arts.push(newArtifact);
            // if (globalData.drawArtifactFunction[newArtifact.SignalId]) {
            //   globalData.drawArtifactFunction[newArtifact.SignalId](newArtifact);
            // }
          }
        });
        globalData.artifacts = arts;
        setArtifacts(arts);
      })();
    },
    [setArtifacts, artifacts, signalIds, deleteEvents, addHistoryEvent],
  );

  useEffect(() => {
    if (firstLoad) {
      if (configurationLoading) return;
      configurationLoading = true;
      setDataLoading(true);
      getToken()
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          globalData.token = data.access_token;
          loadViewConfiguration(studyId);
          firstLoad = false;
        });
    }
  }, [selectedCharts, loadStudySignals, studyId, loadViewConfiguration]);

  const getMinMaxValues = useCallback((data) => {
    const res = [];
    const l = data.length - 1;
    data.forEach((p, i) => {
      if (i > 5 && i < l - 10) {
        if (p.y > data[i - 1].y && p.y >= data[i + 1].y && p.y >= data[i + 10].y && p.y >= data[i + 5].y) {
          res.push(p.x);
        }
        if (p.y < data[i - 1].y && p.y <= data[i + 1].y && p.y <= data[i + 5].y) {
          res.push(p.x);
        }
      }
    });
    return res;
  }, []);

  const drawAirflowLabels = useCallback(() => {
    if (!globalData.showValues[globalData.AirflowSignalId]) {
      if (airflowLabelsPoints.length) {
        airflowLabelsPoints.forEach((e) => {
          e?.dispose();
        });
        airflowLabelsPoints = [];
      }
      return;
    }
    if (globalData.AirflowSignalId && globalData.airflowCycles && selectedChartIds.includes(globalData.AirflowSignalId)) {
      const signalId = globalData.AirflowSignalId;
      const chart = chartsList[signalId];
      if (!chart) return;
      const signalData = signalsData.find((s) => s.SignalId === signalId);
      if (!signalData) return;

      airflowLabelsPoints.forEach((e) => {
        e?.dispose();
      });
      airflowLabelsPoints = [];
      var SeriesMarkerBuilder = MarkerBuilders.XY.setPointMarker(UIBackgrounds.Circle).addStyler((marker) =>
        marker.setPointMarker((point) => point.setSize({ x: 4, y: 4 }).setFillStyle(new SolidFill({ color: ColorHEX('#ab00f5') }))),
      );
      let n = 0;
      globalData.airflowCycles.forEach((c) => {
        const x = c.time;
        if (x < zoomIntervalGlobal.start || x > zoomIntervalGlobal.end) return;
        const p = signalData.data.find((t) => t.x >= x);
        if (p) {
          n++;
          const chartMarker = chart.addChartMarkerXY(SeriesMarkerBuilder).setPosition({ x, y: p.y });

          // Style ChartMarker.
          chartMarker
            .setResultTableVisibility(UIVisibilityModes.always)
            .setMouseInteractions(false)
            .setAutoFitStrategy(undefined)
            .setGridStrokeXVisibility(UIVisibilityModes.never)
            .setGridStrokeYVisibility(UIVisibilityModes.never)
            .setTickMarkerXVisibility(UIVisibilityModes.never)
            .setTickMarkerYVisibility(UIVisibilityModes.never)
            .setDraggingMode(UIDraggingModes.notDraggable)
            .setResultTable((table) => table.setContent([[c.value.toString()]]));

          airflowLabelsPoints.push(chartMarker);
        }
      });
    }
  }, []);

  const drawSpolabels = useCallback(() => {
    if (!globalData.spoSignalId || !selectedChartIds.includes(globalData.spoSignalId)) return;
    if (!globalData.showValues[globalData.spoSignalId]) {
      if (spoLabelPoints?.length) {
        spoLabelPoints.forEach((e) => {
          e?.dispose();
        });
        spoLabelPoints = [];
      }
      return;
    }
    const signalId = globalData.spoSignalId;
    const chart = chartsList[signalId];
    if (!chart) return;
    const signalData = signalsData.find((s) => s.SignalId === signalId);
    if (!signalData) return;

    spoLabelPoints.forEach((e) => {
      e?.dispose();
    });
    spoLabelPoints = [];

    var SeriesMarkerBuilder = MarkerBuilders.XY.setPointMarker(UIBackgrounds.Circle).addStyler((marker) =>
      marker.setPointMarker((point) => point.setSize({ x: 4, y: 4 }).setFillStyle(new SolidFill({ color: ColorHEX('#ab00f5') }))),
    );
    let n = 0;
    spoLabels.forEach((x) => {
      if (n > 50) return;
      if (x < zoomIntervalGlobal.start || x > zoomIntervalGlobal.end) return;
      const p = signalData.data.find((t) => t.x === x);
      if (p) {
        n++;
        const chartMarker = chart.addChartMarkerXY(SeriesMarkerBuilder).setPosition({ x, y: p.y });

        // Style ChartMarker.
        chartMarker
          .setResultTableVisibility(UIVisibilityModes.always)
          .setMouseInteractions(false)
          .setAutoFitStrategy(undefined)
          .setGridStrokeXVisibility(UIVisibilityModes.never)
          .setGridStrokeYVisibility(UIVisibilityModes.never)
          .setTickMarkerXVisibility(UIVisibilityModes.never)
          .setTickMarkerYVisibility(UIVisibilityModes.never)
          .setDraggingMode(UIDraggingModes.notDraggable)
          .setResultTable((table) => table.setContent([[p.y.toString().slice(0, 4)]]));

        spoLabelPoints.push(chartMarker);
      }
    });
    if (n < 4) {
      const dist = zoomIntervalGlobal.end - zoomIntervalGlobal.start;
      const step = Math.floor(dist / 5);
      for (let i = 1; i < 5; i++) {
        const x = zoomIntervalGlobal.start + i * step;
        const p = signalData.data.find((t) => t.x === x);
        if (p) {
          n++;
          const chartMarker = chart.addChartMarkerXY(SeriesMarkerBuilder).setPosition({ x, y: p.y });

          // Style ChartMarker.
          chartMarker
            .setResultTableVisibility(UIVisibilityModes.always)
            .setGridStrokeXVisibility(UIVisibilityModes.never)
            .setGridStrokeYVisibility(UIVisibilityModes.never)
            .setTickMarkerXVisibility(UIVisibilityModes.never)
            .setTickMarkerYVisibility(UIVisibilityModes.never)
            .setDraggingMode(UIDraggingModes.notDraggable)
            .setResultTable((table) => table.setContent([[p.y.toString().slice(0, 4)]]));

          spoLabelPoints.push(chartMarker);
        }
      }
    }
  }, []);

  const drawPulseValues = useCallback(() => {
    drawAirflowLabels();
    drawSpolabels();
    if (!globalData.showValues[globalData.pulseSignalId]) {
      if (pulseLabelsPoints?.length) {
        pulseLabelsPoints.forEach((e) => {
          e?.dispose();
        });
        pulseLabelsPoints = [];
      }
      return;
    }
    if (globalData.pulseSignalId && selectedChartIds.includes(globalData.pulseSignalId)) {
      const signalId = globalData.pulseSignalId;
      const chart = chartsList[signalId];
      if (!chart) return;
      const signalData = signalsData.find((s) => s.SignalId === signalId);
      if (!signalData) return;

      pulseLabelsPoints.forEach((e) => {
        e?.dispose();
      });
      pulseLabelsPoints = [];

      var SeriesMarkerBuilder = MarkerBuilders.XY.setPointMarker(UIBackgrounds.Circle).addStyler((marker) =>
        marker.setPointMarker((point) => point.setSize({ x: 4, y: 4 }).setFillStyle(new SolidFill({ color: ColorHEX('#ab00f5') }))),
      );
      let n = 0;

      pulseLabels.forEach((x) => {
        if (n > 50) return;
        if (x < zoomIntervalGlobal.start || x > zoomIntervalGlobal.end) return;
        const p = signalData.data.find((t) => t.x >= x);
        if (p) {
          if (!p.y) {
            console.log('pulse label error', p, x);
            return;
          }
          n++;
          const chartMarker = chart.addChartMarkerXY(SeriesMarkerBuilder).setPosition({ x, y: p.y });

          // Style ChartMarker.
          chartMarker
            .setResultTableVisibility(UIVisibilityModes.always)
            .setMouseInteractions(false)
            .setAutoFitStrategy(undefined)
            .setGridStrokeXVisibility(UIVisibilityModes.never)
            .setGridStrokeYVisibility(UIVisibilityModes.never)
            .setTickMarkerXVisibility(UIVisibilityModes.never)
            .setTickMarkerYVisibility(UIVisibilityModes.never)
            .setDraggingMode(UIDraggingModes.notDraggable)
            .setResultTable((table) => table.setContent([[p.y.toString().slice(0, 4)]]));

          pulseLabelsPoints.push(chartMarker);
        }
      });
    }
  }, [drawSpolabels, drawAirflowLabels]);

  const updateEventsHeight = useCallback(
    (signalId, range) => {
      const height = range.max - range.min;
      const h = (figureHeight * height) / 100;
      Object.values(globalData.allEventsList).forEach((figures) => {
        if (!figures.length) return;
        let diff = null;
        const top = globalData.eventTopCustom[signalId] ? globalData.eventTopCustom[signalId][figures[0].type] : eventTop[figures[0].type];
        const h2 = figures[0].type === 'RERA' || figures[0].type === 'CheyneStokes' ? h / 2 : h;
        figures.forEach((f) => {
          if (f.signalId !== signalId) return;
          const points = f.figure.getDimensions();
          if (points.length < 5) {
            return;
          }
          //if (event.type === 'RERA' || event.type === 'CheyneStokes') h = h / 2;

          const y = (f.yPos || top) * height + range.min;
          f.figure.setDimensions([
            { x: points[0].x, y: y - h2 },
            { x: points[1].x, y: y - h2 },
            { x: points[2].x, y: y + h2 },
            { x: points[3].x, y: y + h2 },
            { x: points[4].x, y: y },
          ]);

          f.textFigure.setPosition({
            x: (points[4].x + points[1].x) / 2,
            y: y,
            z: 0,
          });

          if (f.resizeElementBack) {
            const points = f.resizeElementBack.getDimensions();
            f.resizeElementBack.setDimensions([
              { x: points[0].x, y: y + h2 },
              { x: points[1].x, y: y + h2 },
              { x: points[2].x, y: y - h2 },
              { x: points[3].x, y: y - h2 },
            ]);
          }
          if (f.resizeElementFront) {
            const points = f.resizeElementFront.getDimensions();
            f.resizeElementFront.setDimensions([
              { x: points[0].x, y: y + h2 / 3 },
              { x: points[1].x, y: y + h2 / 3 },
              { x: points[2].x, y: y - h2 / 3 },
              { x: points[3].x, y: y - h2 / 3 },
            ]);
          }
        });
      });
      if (globalData.sectorPolygons[signalId]) {
        const ranges = globalData.chartRanges;

        globalData.sectorPolygons[signalId].forEach(({ pol, sector }) => {
          pol.setDimensions({
            x1: 0,
            x2: globalData.dateEndTimeDiff,
            y1: ranges[signalId].min + height * sector.min,
            y2: ranges[signalId].min + height * sector.max,
          });
        });
      }
    },
    [eventTop],
  );

  const redrawEvents = useCallback(
    (signalId) => {
      if (!events) return;
      console.log('redraw');
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        updateEventsHeight(parseInt(signalId), globalData.chartRanges[signalId]);
      }, 300);
    },
    [updateEventsHeight, events],
  );

  const redrawEventstext = useCallback(() => {
    Object.values(globalData.allEventsList).forEach((figures) => {
      if (!figures.length || !figures[0].signalId) return;
      const chart = yAxisList[figures[0].signalId].chart;
      figures.forEach((f) => {
        const pixelLocation = translatePoint(
          // axis coordinate.
          { x: f.start, y: 1 },
          {
            x: chart.getDefaultAxisX(),
            y: chart.getDefaultAxisY(),
          },
          chart.pixelScale,
        );
        const pixelLocation2 = translatePoint(
          // axis coordinate.
          { x: f.end, y: 1 },
          {
            x: chart.getDefaultAxisX(),
            y: chart.getDefaultAxisY(),
          },
          chart.pixelScale,
        );
        const l =
          chart.engine.engineLocation2Client(pixelLocation2.x, pixelLocation2.y).x -
          chart.engine.engineLocation2Client(pixelLocation.x, pixelLocation.y).x;
        const text = f.customName || f.text || f.type.replace('Oximetry', '');
        if (l / text.length < 7.5) {
          f.textFigure.setText(text.slice(0, Math.floor(l / 7.5)));
        } else {
          f.textFigure.setText(text);
        }
      });
    });
  }, []);

  const setEventSizeUpdated = useCallback(
    (eventId, signalId, y, h) => {
      const event = globalData.loadedEventsData[signalId].find((ev) => ev.id === eventId);
      if (event)
        setEventSize({
          start: event.startTimeMicro,
          end: event.endTimeMicro,
          h,
          y,
        });
      else console.log('error', eventId, signalId);
    },
    [setEventSize],
  );

  const drawSlider = useCallback(
    (dashboard, signalId, signalData, color, rowSizeSum, artifacts = null) => {
      if (zoomBandChart) {
        try {
          zoomBandChart?.dispose();
        } catch (e) {
          console.log(e);
        }
      }
      const chartNumber = selectedChartIds.length;
      const topAxisSize = Math.floor((window.innerHeight - headerHeight) / 50);
      const chart = chartsList[signalId];
      zoomBandChart = dashboard
        .createZoomBandChart({
          columnIndex: 0,
          columnSpan: 1,
          // rowIndex: Math.floor(4 * (topAxisSize * rowSizeSum + chartNumber)),
          rowIndex: 15 * chartNumber + 5,
          rowSpan: 8 * chartNumber + 6,
          axis: chart.getDefaultAxisX().setTickStrategy(AxisTickStrategies.DateTime),
        })
        .setTitle('');
      zoomBandChart.setPadding({
        left: 0,
        bottom: 0,
        top: 0,
      });
      zoomBandChart.band.setStrokeStyle(
        new SolidLine({
          thickness: 2,
          fillStyle: new SolidFill({ color: ColorRGBA(0, 255, 0) }),
        }),
      );

      zoomBandChart.band.setHighlighted(true);
      if (zoomBandChart.band.getValueStart() !== zoomIntervalGlobal.start) zoomBandChart.band.setValueStart(zoomIntervalGlobal.start);
      if (zoomBandChart.band.getValueEnd() !== zoomIntervalGlobal.end) zoomBandChart.band.setValueEnd(zoomIntervalGlobal.end);
      const t2 = signalData.Type;
      zoomBandChart.setSeriesStyle((series) => {
        if (series.getName() === 'Area Range Series') return;
        series.chart
          .getDefaultAxisY()
          .setNibLength(30)
          .setTickStrategy(
            AxisTickStrategies.Numeric,
            (tickStrategy) =>
              tickStrategy
                .setMajorFormattingFunction((tickPosition) => {
                  return Math.round(tickPosition).toString();
                })
                .setMinorFormattingFunction((tickPosition) => {
                  return Math.round(tickPosition).toString();
                }),
            //.setMinorTickStyle((tickStyle) => tickStyle.setGridStrokeStyle(emptyLine))
          );
        series.setStrokeStyle(
          new SolidLine({
            thickness: 1.1,
            fillStyle: new SolidFill({ color: ColorHEX(color || '#FF0000') }),
          }),
        );

        if (t2 === 'SPO2') {
          zoomBandChart.getDefaultAxisY().setMouseInteractions(true).setInterval(minSpo2Value, 100, true, true);
          zoomBandChart
            .getDefaultAxisY()
            .addCustomTick(UIElementBuilders.AxisTick)
            .setValue(minSpo2Value)
            // Label text is specified with a callback function.
            // This example formats Axis positions with one fraction, like this: "5.0"
            .setTextFormatter((value) => minSpo2Value.toString());

          series.axisY
            .addConstantLine()
            .setValue(90)
            .setStrokeStyle(
              new SolidLine({
                thickness: 1,
                fillStyle: new SolidFill({ color: ColorHEX('#50505010') }),
              }),
            );
          series.axisY
            .addConstantLine()
            .setValue(70)
            .setStrokeStyle(
              new SolidLine({
                thickness: 1,
                fillStyle: new SolidFill({ color: ColorHEX('#50505010') }),
              }),
            );
          series.axisY
            .addConstantLine()
            .setValue(85)
            .setStrokeStyle(
              new SolidLine({
                thickness: 1,
                fillStyle: new SolidFill({ color: ColorHEX('#50505010') }),
              }),
            );
          series.axisY
            .addConstantLine()
            .setValue(75)
            .setStrokeStyle(
              new SolidLine({
                thickness: 1,
                fillStyle: new SolidFill({ color: ColorHEX('#50505010') }),
              }),
            );
          const chart = series.chart;
          const ranges = globalData.chartRanges;
          const height = ranges[signalId].max - ranges[signalId].min;
          (artifacts || globalData.artifacts).forEach((artifact) => {
            if (artifact.removed || artifact.signalId !== globalData.spoSignalId) return;
            globalData.zoomBandArtifacts[artifact.id] = drawEventFigure(
              chart,
              series.axisY,
              series.axisX,
              series.scale,
              ranges[signalId].min,
              '',
              artifact.start,
              artifact.end,
              height,
              true,
            )
              .setFillStyle(
                new SolidFill({
                  color: artifactColors[artifact.Type] || ColorRGBA(0, 0, 0, 80),
                }),
              )
              .setStrokeStyle(strokeStyle);
            addSliderArtifactMoving(
              artifact,
              setArtifacts,
              globalData.zoomBandArtifacts[artifact.id],
              chart,
              series,
              () => {},
              signalId,
              artifact.sliderId,
              deleteArtifact,
              addSliderArtifact,
            );
            const mouseMoveEvent = (t, e) => {
              setHoverElement({
                position: { x: e.pageX, y: e.pageY },
                text: 'Artifact',
                size: { start: artifact.start, end: artifact.end },
                isArtifact: true,
                sliderId: artifact.sliderId,
                id: artifact.id,
                type: artifact.Type,
                status: artifact.status,
              });
              //splineSeries.setCursorEnabled(false);
              globalData.zoomBandArtifacts[artifact.id].setStrokeStyle(
                new SolidLine({
                  fillStyle: new SolidFill({ color: ColorHEX('#ffc107') }),
                  thickness: 3,
                }),
              );
            };
            globalData.zoomBandArtifacts[artifact.id].onMouseMove(mouseMoveEvent);
            globalData.zoomBandArtifacts[artifact.id].onMouseLeave((t, e) => {
              globalData.zoomBandArtifacts[artifact.id].setStrokeStyle(
                new SolidLine({
                  fillStyle: new SolidFill({ color: ColorRGBA(0, 0, 0, 241) }),
                  thickness: 1,
                }),
              );
            });

            // setHoverElement({
            //   position: { x: e.pageX, y: e.pageY },
            //   text: 'Artifact',
            //   size: { start: artifact.start, end: artifact.end },
            //   isArtifact: true,
            //   id: artifact.id,
            //   type: artifact.Type,
            //   status: artifact.status,
            // });
          });

          chart.onSeriesBackgroundMouseDragStart((_, event, button) => {
            lastDrawingPolygonFigure = null;
            if (button !== 0 || !window.artifactDrawing) return;
            globalData.lastTextElement = null;
            const curLocationAxis = translatePoint(
              chart.engine.clientLocation2Engine(event.clientX, event.clientY),
              chart.engine.scale,
              series.scale,
            );
            startPoint = curLocationAxis;
            currentChartHeight = globalData.chartRanges[signalId].max - globalData.chartRanges[signalId].min;
          });
          chart.onSeriesBackgroundMouseDrag((_, event, button) => {
            if (button !== 0 || !window.artifactDrawing) return;
            const curLocationAxis = translatePoint(
              chart.engine.clientLocation2Engine(event.clientX, event.clientY),
              chart.engine.scale,
              series.scale,
            );
            globalData.lastTextElement?.dispose();
            if (lastDrawingPolygonFigure) lastDrawingPolygonFigure?.dispose();
            const mouseOnRightSide = curLocationAxis.x > startPoint.x;
            setHoverElement({
              position: { x: event.pageX, y: event.pageY },
              text: 'Artifact',
              size: {
                start: Math.min(curLocationAxis.x, startPoint.x),
                end: Math.max(curLocationAxis.x, startPoint.x),
              },
              isEventDrawing: true,
              onRight: mouseOnRightSide,
            });
            const y = (startPoint.y + curLocationAxis.y) / 2;
            const h = currentChartHeight * 0.95;
            if (mouseOnRightSide)
              lastDrawingPolygonFigure = drawEventFigure(
                chart,
                series.axisY,
                series.axisX,
                series.scale,
                y,
                '',
                startPoint.x,
                curLocationAxis.x,
                h,
                true,
              )
                .setFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 80) }))
                .setStrokeStyle(strokeStyle);
            else
              lastDrawingPolygonFigure = drawEventFigure(
                chart,
                series.axisY,
                series.axisX,
                series.scale,
                y,
                '',
                curLocationAxis.x,
                startPoint.x,
                h,
                true,
              )
                .setFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 80) }))
                .setStrokeStyle(strokeStyle);
          });

          chart.onSeriesBackgroundMouseDragStop((_, event, button) => {
            if (button !== 0 || !window.artifactDrawing) return;
            const curLocationAxis = translatePoint(
              chart.engine.clientLocation2Engine(event.clientX, event.clientY),
              chart.engine.scale,
              series.scale,
            );
            const y = (startPoint.y + curLocationAxis.y) / 2;
            let start = startPoint.x;
            let end = curLocationAxis.x;
            if (Math.abs(end - start) < 2) return;

            if (curLocationAxis.x < startPoint.x) {
              end = startPoint.x;
              start = curLocationAxis.x;
            }
            setHoverElement(null);
            if (lastDrawingPolygonFigure) lastDrawingPolygonFigure?.dispose();
            lastPolygonFigure = drawEventFigure(
              chart,
              series.axisY,
              series.axisX,
              series.scale,
              y,
              '',
              start,
              end,
              window.artifactDrawing ? height : (figureHeight * height) / 100,
            )
              .setFillStyle(fillStyle)
              .setStrokeStyle(strokeStyle);
            // addArtifact(start, end, signalId, true);
            addSliderArtifact(start, end);
          });
        }
      });
    },
    [addArtifact],
  );

  const addPolygonEvent = useCallback(
    (sigId, yPos, start, end, text) => {
      const id = eventId;
      const newEvents = { ...globalData.loadedEventsData };

      newEvents[sigId] = globalData.loadedEventsData[sigId] ? globalData.loadedEventsData[sigId].slice(0) : [];
      const newEvent = {
        StartTime: moment(start + dateOriginTime)
          .utcOffset(0, true)
          .format()
          .replace('Z', ''),
        EndTime: moment(end + dateOriginTime)
          .utcOffset(0, true)
          .format()
          .replace('Z', ''),
        Type: text,
        type: text,
        id,
        SignalId: sigId,
        Status: 'Altered',
        status: 'Altered',
        added: true,
        startTimeMicro: start,
        endTimeMicro: end,
        yPos,
      };

      if (!globalData.allEventsList[text]) globalData.allEventsList[text] = [];
      else {
        const crossedEvent = checkOverlap(text, id, sigId, start, end, globalData.allEventsList);
        if (crossedEvent) {
          alert(crossedEvent);
          return;
        }
      }
      if (newEventSize?.customFields)
        newEvent.CustomCharacteristics = {
          ...newEvent.CustomCharacteristics,
          ...newEventSize?.customFields,
        };
      newEvents[sigId].push(newEvent);
      (async () => {
        const e = await saveEvent(newEvent, sigId);
        if (e) {
          // addHistoryEvent({
          //   signalId: sigId,
          //   prev: events[sigId],
          //   next: newEvents[sigId],
          //   type: 'event',
          //   title: 'add event',
          // });
          //redrawCharts = true;
          newEvents[sigId][newEvents[sigId].length - 1].SleepEventId = e.SleepEventId;
          globalData.loadedEventsData[sigId] = newEvents[sigId];
          globalData.eventsUpdated = true;
          setEvents(newEvents);
          //globalData.allEventsList[text].push({ ...tempEvent1, yPos, start, end, Type: text, id: eventId, signalId: sigId });
          eventId++;
          //addEventActivate();
          globalData.drawEventFunction[sigId](newEvent, sigId);
          lastPolygonFigure = null;
        } else {
          alert('Error occured when saving events');
        }
      })();
    },
    [events, artifacts],
  );

  const drawLSLabels = useCallback(() => {
    const signalId = Object.keys(chartsList)[0];
    const chart = chartsList[signalId];
    const axisX = chart.getDefaultAxisX();
    if (!axisX) return;
    const axisY = chart.getDefaultAxisY();
    const height = globalData.chartRanges[signalId].max - globalData.chartRanges[signalId].min;
    const y = 0.5 * height + globalData.chartRanges[signalId].min;
    const offset = globalData.dateOriginTime % 30000;
    drawVertLines();
    const l = globalData.dateEndTimeDiff / 30000;
    for (let i = 1; i < l; i++) {
      let x = i * 30000 - offset;
      if (x < zoomIntervalGlobal.start || x > zoomIntervalGlobal.end) continue;
      const pixelLocation2 = translatePoint(
        // axis coordinate.
        { x: x, y: y },
        {
          x: axisX,
          y: axisY,
        },
        chart.pixelScale,
      );
      globalData.vertLines.push({ x: pixelLocation2.x + 59 - (i > 2 ? 2 : 0), t: x, i });
    }
    drawVertLines(globalData.vertLines);
    if (!globalData.wakeData) return;
    lsWaterMarks.forEach((w) => {
      w.dispose();
    });
    let i1 = Math.floor(zoomIntervalGlobal.start / 30000);
    let i2 = Math.floor(zoomIntervalGlobal.end / 30000);
    //if (i2 >= globalData.wakeData.length) i2 = globalData.wakeData.length - 1;

    globalData.waterMarkClick = (x, waterMark, hide = false) => {
      if (hide) {
        waterMark.setTextFillStyle(new SolidFill({ color: ColorRGBA(0, globalData.selectedSleepElement?.added ? 200 : 0, 0, 100) }));
        const el = document.getElementById('sleepStage');
        el.style.display = 'none';
        globalData.selectedSleepElement = null;
        return;
      }
      waterMark.setTextFillStyle(new SolidFill({ color: ColorRGBA(200, 0, 0, 100) }));
      const pixelLocationLeft = translatePoint(
        // axis coordinate.
        { x: x - 15000, y: y },
        {
          x: axisX,
          y: axisY,
        },
        chart.pixelScale,
      );
      const pixelLocationRight = translatePoint(
        // axis coordinate.
        { x: x + 15000, y: y },
        {
          x: axisX,
          y: axisY,
        },
        chart.pixelScale,
      );
      const locationLeft = chart.engine.clientLocation2Engine(pixelLocationLeft.x + 70, window.outerHeight / 2.1);
      //const locationRight = chart.engine.clientLocation2Engine(pixelLocationRight.x + 70, window.outerHeight / 2.1);
      sleepStageSelected(pixelLocationLeft.x + 59, pixelLocationRight.x + 59, locationLeft.y);
      waterMark.setTextFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 100) }));
    };
    try {
      if (globalData.wakeData) {
        const el = document.getElementById('waterMarks');
        el.innerHTML = '';
        i1 = globalData.wakeData.findIndex((t) => t.i1 >= i1 || t.i2 > i1);
        i2 = globalData.wakeData.findIndex((t) => t.i1 >= i2);
        if (i2 === -1) i2 = globalData.wakeData.length - 1;
        for (let i = i1; i < i2; i++) {
          for (let j = globalData.wakeData[i].i1; j < globalData.wakeData[i].i2; j++) {
            let x = j * 30000 + 15000 - offset;
            if (x < zoomIntervalGlobal.start || x > zoomIntervalGlobal.end) continue;
            const pixelLocation = translatePoint(
              // axis coordinate.
              { x, y: y },
              {
                x: axisX,
                y: axisY,
              },
              chart.pixelScale,
            );

            const location1 = chart.engine.clientLocation2Engine(pixelLocation.x + 55, window.outerHeight / 2.1);
            const location2 = translatePoint(location1, dashboardObject.current.engine.scale, dashboardObject.current.uiScale);
            const text = globalData.wakeData[i].text;
            const added = globalData.wakeData[i].added;
            const waterMark = dashboardObject.current.addUIElement(
              UIElementBuilders.TextBox.addStyler((marker) => {
                marker.setTextFillStyle(new SolidFill({ color: ColorRGBA(200, 0, 0, 100) }));
                return marker;
              }),
            );
            waterMark
              .setText(text)
              .setDraggingMode(lcjs.UIDraggingModes.notDraggable)
              .setMouseInteractions(true)
              .setPosition(location2)
              .setBackground((bg) => bg.setFillStyle(emptyFill).setStrokeStyle(emptyLine))
              .setTextFont(
                new FontSettings({
                  size: 45,
                  family: 'Segoe UI',
                }),
              )
              .setTextFillStyle(new SolidFill({ color: ColorRGBA(0, added ? 200 : 0, 0, 100) }));

            waterMark.onMouseEnter((t, e) => {
              console.log('over');
              waterMark.setTextFillStyle(new SolidFill({ color: ColorRGBA(200, 0, 0, 100) }));
            });
            waterMark.onMouseLeave((t, e) => {
              console.log('leave');
              waterMark.setTextFillStyle(new SolidFill({ color: ColorRGBA(0, added ? 200 : 0, 0, 100) }));
            });

            waterMark.onMouseClick((t, e) => {
              if (globalData.selectedSleepElement && globalData.selectedSleepElement.j === j) {
                globalData.waterMarkClick(x, waterMark, true);
                return;
              }
              globalData.waterMarkClick(x, waterMark);
              globalData.selectedSleepElement = {
                waterMark,
                i,
                j,
                added,
                x,
                lastOnScreen: x + 30000 >= zoomIntervalGlobal.end,
                last: j === globalData.wakeData[i].i2 - 1,
                first: j === globalData.wakeData[i].i1,
              };
            });
            if (globalData.selectedSleepElement && globalData.selectedSleepElement.j === j) {
              globalData.selectedSleepElement.moving = false;
              globalData.selectedSleepElement.waterMark = waterMark;
              globalData.selectedSleepElement.i = i;
              globalData.waterMarkClick(x, waterMark);
            }
            lsWaterMarks.push(waterMark);
            // el.innerHTML += `<div style='left:${
            //   pixelLocation.x + 40
            // }px'>LS</div>`;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const zoomUpdate = useCallback(
    (start, end) => {
      if (start < 0) {
        const t = end - start;
        globalData.firstChart.setInterval(0, t);
        return;
      }
      if (!globalData.selectedSleepElement?.moving) globalData.selectedSleepElement = null;
      sleepStageUnSelected();
      start = Math.floor(start);
      end = Math.floor(end);
      if (start > end) {
        const t = start;
        start = end;
        end = t;
      }
      zoomIntervalGlobal = { start, end };
      drawPulseValues();
      if (positionSignal) {
        let x = Math.floor((end * positionSignal.rate) / 1000);
        if (x >= positionSignal.data.length) x = positionSignal.data.length - 1;
        imageRotation = getBodyRotation(positionSignal.data[x].y);
        document.getElementById('body').style = `transform:rotateZ(${imageRotation}deg)`;
        bodyImage = getBodyImage(positionSignal.data[x].y);
        document.getElementById('body').src = bodyImage;
      }
      if (!zoomInterval || Math.floor(end - start) - bandSize !== 0) {
        bandSize = Math.floor(end - start);
        const d = (end - start) / 60000;
        minutesNumber = d < 1 ? (d <= 0.5 ? 0.5 : 1) : Math.floor((end - start) / 60000);
        if (!selectedTimeInterval.label !== 'custom' && !timeIntervalSizes.find((t) => t.value === minutesNumber * 60))
          setSelectedTimeTinterval({
            label: 'custom',
            value: minutesNumber * 60,
          });
        setZoomInterval({ start, end });

        const preventDelete = currentDrawingSignalId || globalData.resizeDragOutside || globalData.eventPressed;

        redrawEventstext();

        resizeElements.forEach((el) => {
          const points = el.getDimensions();
          const x = (points[0].x + points[1].x) / 2;
          const dist = (end - start) / 600;
          points[0].x = x - dist;
          points[1].x = x + dist;
          points[2].x = x + dist;
          points[3].x = x - dist;
          el.setDimensions(points);
        });
        //drawCharts();
      }
      const preventDelete = currentDrawingSignalId || globalData.resizeDragOutside || globalData.eventPressed;
      const eventId = globalData.eventPressed?.id || globalData.resizerPressed?.id;
      if (eventId) {
        visibleEventPolygons.forEach((f) => {
          if (f.id === eventId) return;
          f.figure?.dispose();
          f.textFigure?.dispose();
        });
      } else {
        visibleEventPolygons.forEach((f) => {
          f.figure?.dispose();
          f.textFigure?.dispose();
        });
      }
      // if (globalData.lastResizedEvent) {
      //   globalData.lastResizedEvent?.figure?.dispose();
      //   globalData.lastResizedEvent?.textFigure?.dispose();
      //   globalData.lastResizedEvent = null;
      // }
      visibleEventPolygons = [];
      Object.keys(globalData.allEventsList).forEach((key) => {
        globalData.allEventsList[key] = [];
      });

      selectedChartIds.forEach((SignalId) => {
        // if (!events[signalData.SignalId]) return;

        globalData.loadedEventsData[SignalId].forEach((event) => {
          if (event.startTimeMicro > zoomIntervalGlobal.end || event.endTimeMicro < zoomIntervalGlobal.start) return;
          if (event.id === eventId) return;
          globalData.drawEventFunction[SignalId](event, SignalId);
        });
      });

      searchedEventId = 0;
      Object.keys(globalData.allEventsList).forEach((eventType) => {
        if (!globalData.visibleEvents.includes(eventType)) {
          globalData.allEventsList[eventType].forEach((e) => {
            e.figure?.dispose();
            e.textFigure?.dispose();
            e.resizeElementFront?.dispose();
            e.resizeElementBack?.dispose();
          });
        }
      });
      drawLSLabels();
    },
    [zoomInterval, setZoomInterval, redrawEventstext, drawPulseValues, drawLSLabels, selectedTimeInterval],
  );

  const draggingOutsideStart = useCallback((signalId, y, right, pol = true, isArtifact = false) => {
    if (!lastDrawingPolygonFigure) {
      alert('error here');
      return;
    }
    const t = Math.floor((zoomIntervalGlobal.end - zoomIntervalGlobal.start) / 80);
    const points = lastDrawingPolygonFigure.getDimensions();
    rightDraggingoutside = right;
    draggingOutsideTimer = setInterval(() => {
      let x = right ? zoomIntervalGlobal.end + t : zoomIntervalGlobal.start - t;
      if (right && x >= globalData.dateEndTimeDiff) x = globalData.dateEndTimeDiff;
      if (!right && x < 0) x = 0;

      globalData.firstChart.setInterval(right ? zoomIntervalGlobal.start : x, right ? x : zoomIntervalGlobal.end);
      zoomUpdate(right ? zoomIntervalGlobal.start : x, right ? x : zoomIntervalGlobal.end);
      if (pol) {
        globalData.drawingPolygonEventFunction({ x: x, y }, currentDrawingSector, signalId);
      } else {
        if (right) {
          points[1].x = x;
          points[2].x = x;
        } else {
          points[0].x = x;
          points[3].x = x;
          points[4].x = x;
        }
        lastDrawingPolygonFigure.setDimensions(points);
      }
      if (x >= globalData.dateEndTimeDiff || x <= 0) {
        clearInterval(draggingOutsideTimer);
        if (pol) {
          globalData.drawPolygonEventFunction(
            right ? startPoint.x : 0,
            right ? zoomIntervalGlobal.end + 100 : startPoint.x,
            currentDrawingSignalId,
          );
        }
        draggingOutsideTimer = null;
      }
    }, 100);
  }, []);

  const draggingOutsideEnd = useCallback((e = null) => {
    if (globalData.eventPressed && draggingOutsideTimer) {
      const diff = globalData.eventPressed.newPoints ? globalData.eventPressed.newPoints[1].x - globalData.eventPressed.points[1].x : 0;
      if (globalData.eventPressed.isArtifact) {
        if (globalData.moveArtifact) globalData.moveArtifact(diff);
      } else {
        console.log('move event end');
        moveEvent(
          globalData.eventPressed.id,
          globalData.eventPressed.signalId,
          globalData.loadedEventsData,
          setEvents,
          globalData.eventPressed.type,
          diff,
          diff,
          globalData.eventPressed.points,
          globalData.eventPressed.figure,
          '',
          chartsList[globalData.eventPressed.signalId],
        );

        globalData.eventPressed.figure?.dispose();
        globalData.eventPressed.textFigure?.dispose();
      }
      clearInterval(draggingOutsideTimer);
      draggingOutsideTimer = null;
      globalData.eventPressed = null;
      return;
    }

    if (globalData.resizeDragOutside && globalData.resizerPressed) {
      const points = globalData.resizerPressed.figure.getDimensions();
      const x = globalData.resizerPressed.front ? points[0].x : points[1].x;
      globalData.lastResizedEvent = {
        figure: globalData.resizerPressed?.figure,
        textFigure: globalData.resizerPressed?.textFigure,
      };
      const t = globalData.resizerPressed;
      globalData.resizeDragStop && globalData.resizeDragStop(x);
      if (!t?.isArtifact && draggingOutsideTimer) {
        t?.figure?.dispose();
        t?.textFigure?.dispose();
      }

      globalData.resizerPressed = null;
      globalData.resizeDragOutside = null;
      globalData.currentDrawingSignalId = null;
      globalData.lastDragOutsideTime = Date.now();
      if (draggingOutsideTimer) clearInterval(draggingOutsideTimer);
      draggingOutsideTimer = null;
      return;
    }
    if (!lastDrawingPolygonFigure) return;
    const points = lastDrawingPolygonFigure.getDimensions();
    let start = points[0].x;
    let end = points[1].x;

    if ((!draggingOutsideTimer && !currentDrawingSector) || !startPoint) return;
    if (draggingOutsideTimer) clearInterval(draggingOutsideTimer);
    lastDrawingPolygonFigure?.dispose();
    lastDrawingPolygonFigure = null;
    if (globalData.drawPolygonEventFunction && currentDrawingSector) {
      globalData.drawPolygonEventFunction(start, end, currentDrawingSignalId);
    } else {
      const start = points[4].x;
      const end = points[1].x;
      const y = points[4].y;

      // globalData.lastTextElement = chartsList[currentDrawingSignalId]
      //   .addUIElement(UIElementBuilders.TextBox, scale)
      //   .setText(text)
      //   .setMouseInteractions(false)
      //   .setDraggingMode(lcjs.UIDraggingModes.onlyHorizontal)
      //   .setPosition({ x: (start + end) / 2, y: y, z: 0 })
      //   .setTextFillStyle(chart.getSeriesBackgroundFillStyle())
      //   .setBackground((background) =>
      //     background.setFillStyle(emptyFill).setStrokeStyle(emptyLine),
      //   );
      if (window.artifactDrawing) {
        addArtifact(start, end, currentDrawingSignalId);
        return;
      } else {
        tempEvent = {
          signalIndex: chartSignalDrawData[currentDrawingSignalId].signalIndex,
          start,
          end,
        };
        setEventSize({ start, end, figureHeight, y });
        setModalOpen(currentDrawingSignalId);
        setEventEditMode(false);
      }
    }
    draggingOutsideTimer = null;
    currentDrawingSignalId = null;
    currentDrawingSector = null;
  }, []);

  const drawSignal = useCallback(
    (redraw, index, signalIndex, signalData, rowIndex, topAxisSize, rowSizeSum) => {
      const signalId = signalData.SignalId;
      const ranges = globalData.chartRanges;

      const dashboard = dashboardObject.current;
      if (redraw) {
        const chart = dashboard
          .createChartXY({
            columnIndex: 0,
            columnSpan: 1,
            rowIndex: rowIndex,
            rowSpan: Math.floor(4 * topAxisSize * chartSizes[signalId]),
            theme: myTheme,
          })
          .setTitleFont((font) => font.setSize(10))
          .setPadding({
            right: 10,
            left: 0,
            //  top: 1,
            bottom: 1,
            top: index === 0 ? 0 : 5,
          })
          .setMouseInteractionRectangleZoom(false)
          .setMouseInteractionRectangleFit(false)
          .setTitle('')
          .setTitleFillStyle(emptyFill)
          .setTitleMarginTop(0)
          .setTitleMarginBottom(-20)
          .setBackgroundStrokeStyle(emptyLine)
          .setFittingRectangleStrokeStyle(fittingRectangleStrokeStyle)
          .setZoomingRectangleFillStyle(zoomingRectangleFillStyle)
          .setMouseInteractionWheelZoom(false);
        chartsList[signalId] = chart;
        globalData.chartsList[signalId] = chart;
        rowIndex += Math.floor(4 * topAxisSize * chartSizes[signalId]);
        chart.setAutoCursor((cursor) => {
          cursor
            .setResultTableAutoTextStyle(true)
            .disposeTickMarkerX()
            .setTickMarkerXAutoTextStyle(false)
            .setTickMarkerYAutoTextStyle(false);
        });
        chart.onBackgroundMouseLeave((t, e) => {
          setHoverElement(null);
        });
        //chart.setBackgroundFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 100) }));
        xAxisList[index] = chart
          .getDefaultAxisX()
          .setOverlayStyle(axisXStyleHighlight)
          .setNibOverlayStyle(axisXStyleHighlight)
          .setScrollStrategy(undefined)
          .setThickness(0)
          .setNibLength(0)
          .setNibStyle(emptyLine)
          .setTickStrategy(AxisTickStrategies.DateTime, (dateTimeTicks) =>
            dateTimeTicks
              .setDateOrigin(dateOrigin)
              .setGreatTickStyle(emptyTick)
              .setMajorTickStyle((tickStyle) => {
                return globalData.gridLines[signalId]
                  ? tickStyle.setLabelFont((font) => font.setSize(0)).setTickLength(0)
                  : tickStyle
                      .setGridStrokeStyle(emptyLine)
                      .setLabelFont((font) => font.setSize(0))
                      .setTickLength(0);
              })
              .setMinorTickStyle((tickStyle) =>
                globalData.gridLines[signalId]
                  ? tickStyle.setLabelFont((font) => font.setSize(0)).setTickLength(0)
                  : tickStyle
                      .setGridStrokeStyle(emptyLine)
                      .setLabelFont((font) => font.setSize(0))
                      .setTickLength(0),
              ),
          )
          .setAnimationScroll(false);
        const axisY = chart
          .getDefaultAxisY()
          .setStrokeStyle(axisYStrokeStyles[0])
          .setTitle('')
          .setOverlayStyle(axisYStylesHighlight[0])
          .setNibOverlayStyle(axisYStylesHighlight[0])
          .setInterval(0, 100)
          .setNibLength(10)
          .setTitleFont(
            new FontSettings({
              size: 11,
              family: 'Arial, Helvetica, sans-serif',
              weight: 'bold',
              style: 'italic',
            }),
          )
          .setTickStrategy(AxisTickStrategies.Numeric, (tickStrategy) =>
            tickStrategy
              .setMajorFormattingFunction((tickPosition) => {
                return NoYLabelSignals.includes(signalData.Type) ? '' : Math.round(tickPosition).toString();
              })
              .setMinorFormattingFunction((tickPosition) => {
                return NoYLabelSignals.includes(signalData.Type) ? '' : Math.round(tickPosition).toString();
              })
              .setMajorTickStyle((tickStyle) => (globalData.gridLines[signalId] ? tickStyle : tickStyle.setGridStrokeStyle(emptyLine)))
              .setMinorTickStyle((tickStyle) => (globalData.gridLines[signalId] ? tickStyle : tickStyle.setGridStrokeStyle(emptyLine))),
          )
          .setScrollStrategy(AxisScrollStrategies.regressive);
        yAxisList[signalId] = axisY;
      }
      const chart = chartsList[signalId];
      const axisY = yAxisList[signalId];
      chart.addPolygonSeries().clear();
      axisY
        .addCustomTick()
        .setTickLength(60)
        .setTextFormatter((position, customTick) => '');
      const splineSeries = chart.addLineSeries({
        xAxis: xAxisList[index],
        yAxis: axisY,
        pointShape: PointShape.Circle,
        dataPattern: {
          pattern: 'ProgressiveX',
          regularProgressiveStep: true,
        },
      });
      //splineSeries.setMaxPointCount(100);
      if (signalData.name === 'SPO2') {
        axisY
          .addCustomTick(UIElementBuilders.AxisTick)
          .setValue(85)
          .setTextFormatter((_) => '85');
        axisY
          .addCustomTick(UIElementBuilders.AxisTick)
          .setValue(95)
          .setTextFormatter((_) => '95');
      }
      // .setDrawOrder({ seriesDrawOrderIndex: 11 });
      //splineSeries1.setPointSize(2);
      splineSeries.setCursorResultTableFormatter((tableBuilder, series, x, y) => {
        const d = moment(new Date(x + dateOrigin.getTime()))
          .utcOffset(0, true)
          .format()
          .replace('T', ' ')
          .slice(0, 19);
        return (
          tableBuilder
            .addRow(signalData.name)
            //.addRow("Time1: " + x)
            .addRow('Time: ' + d)
            .addRow(['SPO2', 'Pulse', 'Snore'].includes(signalData.name) ? 'Value: ' + y.toFixed(2) : undefined)
        );
      });
      //if (signalData.name === 'Pulse') {
      // chartsList[signalId].setSeriesBackgroundFillStyle(
      //   new SolidFill({ color: ColorHEX('#ffff9940') }),
      // );
      //}
      splineSeries.setStrokeStyle(
        new SolidLine({
          thickness: 2,
          fillStyle: new SolidFill({
            color: ColorHEX(globalData.chartColors[signalId] || '#FF0000'),
          }),
        }),
      );

      if (globalData.maxValuesLines[signalId]) {
        const maxValuesLine = chart.addLineSeries({
          xAxis: xAxisList[index],
          yAxis: axisY,
          pointShape: PointShape.Circle,
          dataPattern: {
            pattern: 'ProgressiveX',
            regularProgressiveStep: true,
          },
        });
        maxValuesLine.setMaxPointCount(1000);
        maxValuesLine.add(globalData.maxValuesLines[signalId]);
        maxValuesLine.setCursorEnabled(false);
      }
      if (globalData.minValuesLines[signalId]) {
        const minValuesLine = chart.addLineSeries({
          xAxis: xAxisList[index],
          yAxis: axisY,
          pointShape: PointShape.Circle,
          dataPattern: {
            pattern: 'ProgressiveX',
            regularProgressiveStep: true,
          },
        });
        minValuesLine.setMaxPointCount(1000);
        minValuesLine.add(globalData.minValuesLines[signalId]);
        minValuesLine.setCursorEnabled(false);
      }
      signalChartsList[signalId] = splineSeries;
      if (globalData.additionalLines[signalId]) {
        const id = globalData.additionalLines[signalId];
        const splineSeries = chart.addLineSeries({
          xAxis: xAxisList[index],
          yAxis: axisY,
          pointShape: PointShape.Circle,
          dataPattern: {
            pattern: 'ProgressiveX',
            regularProgressiveStep: true,
          },
        });
        splineSeries
          .setCursorResultTableFormatter((tableBuilder, series, x, y) => {
            const d = moment(new Date(x + dateOrigin.getTime()))
              .utcOffset(0, true)
              .format()
              .replace('T', ' ')
              .slice(0, 19);
            return tableBuilder
              .addRow(globalData.loadedChartsData[id].name)
              .addRow('Time: ' + d)
              .addRow('Value: ' + y.toFixed(2));
          })
          .setStrokeStyle(
            new SolidLine({
              thickness: 2,
              fillStyle: new SolidFill({
                color: ColorHEX(globalData.chartColors[id] || '#FF0000'),
              }),
            }),
          );
        signalChartsList[id] = splineSeries;
        splineSeries.add(globalData.loadedChartsData[id].data);
      }

      if (zoomIdActual === signalId) {
        drawSlider(dashboard, zoomIdActual, signalData, globalData.chartColors[signalId], rowSizeSum);
        // globalData.drawSliderFunction = (artifacts) => {
        //   drawSlider(dashboard, zoomIdActual, signalData, globalData.chartColors[signalId], rowSizeSum, artifacts);
        // };
      }
      splineSeries.add(signalData.data);
      signalLines[signalId] = splineSeries;

      if (!ranges[signalId]) {
        const min = splineSeries.getYMin() - 30;
        const max = splineSeries.getYMax() + 50;
        axisY.setInterval(min, max, true, true);
        // ranges[signalId] = { min: min, max: max };
        autoChartRanges[signalId] = { min: min, max: max };
      } else {
        axisY.setInterval(ranges[signalId].min, ranges[signalId].max, true, true);
      }

      if (signalId === globalData.pulseSignalId && globalData.pulseTags) {
        drawPulseTags(chart);
        axisY.onScaleChange((start, end) => {
          globalData.chartRanges[signalId] = { min: start, max: end };
          redrawEvents(signalId);
          drawPulseTags(chart, start, end);
        });
      } else {
        axisY.onScaleChange((start, end) => {
          globalData.chartRanges[signalId] = { min: start, max: end };
          redrawEvents(signalId);
        });
      }

      const height = ranges[signalId].max - ranges[signalId].min;
      if (eventSectors[signalData.name] && redraw) {
        globalData.sectorPolygons[signalId] = [];
        eventSectors[signalData.name].forEach((sector, i) => {
          if (sector.type === 'None') return;
          const pol = chart
            .addRectangleSeries()
            .add({
              x1: 0,
              x2: globalData.dateEndTimeDiff,
              y1: ranges[signalId].min + height * sector.min,
              y2: ranges[signalId].min + height * sector.max,
            })
            .setMouseInteractions(false);
          pol.setFillStyle(new SolidFill({ color: ColorHEX(sector.color + '70') })).setStrokeStyle(strokeStyle2);

          pol.onMouseClick((_, event, button) => {
            console.log('mouse click');
            if (currentDrawingSignalId && currentDrawingSector) {
              lastDrawingPolygonFigure?.dispose();
              currentDrawingSignalId = null;
              currentDrawingSector = null;
              startPoint = null;
            }
          });
          pol.onMouseDragStart((_, event, button) => {
            console.log('drag start');
            lastDrawingPolygonFigure = null;
            if (button !== 0) return;
            globalData.lastTextElement = null;
            const curLocationAxis = translatePoint(
              chart.engine.clientLocation2Engine(event.clientX, event.clientY),
              chart.engine.scale,
              splineSeries.scale,
            );
            startPoint = curLocationAxis;
            currentDrawingSector = sector;
            currentDrawingSignalId = signalId;
            currentChartHeight = globalData.chartRanges[signalId].max - globalData.chartRanges[signalId].min;

            globalData.drawingPolygonEventFunction = (curLocationAxis, sector, signalId) => {
              if (!startPoint) return;
              globalData.lastTextElement?.dispose();
              lastDrawingPolygonFigure?.dispose();
              const mouseOnRightSide = curLocationAxis.x > startPoint.x;
              setHoverElement({
                position: { x: event.pageX, y: event.pageY },
                text: 'Artifact',
                size: {
                  start: Math.min(curLocationAxis.x, startPoint.x),
                  end: Math.max(curLocationAxis.x, startPoint.x),
                },
                isEventDrawing: true,
                onRight: mouseOnRightSide,
              });
              if (window.artifactDrawing) {
                const y = (startPoint.y + curLocationAxis.y) / 2;
                const h = currentChartHeight * 0.95;
                if (mouseOnRightSide)
                  lastDrawingPolygonFigure = drawEventFigure(
                    chart,
                    axisY,
                    xAxisList[index],
                    splineSeries.scale,
                    y,
                    '',
                    startPoint.x,
                    curLocationAxis.x,
                    h,
                    true,
                  )
                    .setFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 80) }))
                    .setStrokeStyle(strokeStyle);
                else
                  lastDrawingPolygonFigure = drawEventFigure(
                    chart,
                    axisY,
                    xAxisList[index],
                    splineSeries.scale,
                    y,
                    '',
                    curLocationAxis.x,
                    startPoint.x,
                    h,
                    true,
                  )
                    .setFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 80) }))
                    .setStrokeStyle(strokeStyle);
                return;
              }

              const y = (startPoint.y + curLocationAxis.y) / 2;
              let h = (figureHeight * currentChartHeight) / 100;
              if (currentDrawingSector.type === 'RERA' || currentDrawingSector.type === 'CheyneStokes') h = h / 2;
              if (mouseOnRightSide)
                lastDrawingPolygonFigure = drawPolygonEvent(chart, y, signalId, currentDrawingSector, startPoint.x, curLocationAxis.x, h)
                  .setFillStyle(fillStyle)
                  .setStrokeStyle(strokeStyle);
              else
                lastDrawingPolygonFigure = drawPolygonEvent(chart, y, signalId, sector, curLocationAxis.x, startPoint.x, h)
                  .setFillStyle(fillStyle)
                  .setStrokeStyle(strokeStyle);
            };
            globalData.drawPolygonEventFunction = (start, end, signalId) => {
              console.log('drag stop ');

              setHoverElement(null);
              lastDrawingPolygonFigure?.dispose();
              if (window.artifactDrawing) {
                addArtifact(start, end, signalId);
                return;
              }

              addPolygonEvent(signalId, (currentDrawingSector.min + currentDrawingSector.max) / 2, start, end, currentDrawingSector.type);
              startPoint = null;
            };
          });

          globalData.sectorPolygons[signalId].push({ pol, sector });
        });
      }

      //  const categories = events[signalId].map((t) => addCategory(t.y));
      //const y = 0.5 * height + ranges[signalId].min;
      // const waterMark = chart
      //   .addUIElement(UIElementBuilders.TextBox, { x: xAxisList[index], y: axisY })
      //   .setText("WK")
      //   .setDraggingMode(lcjs.UIDraggingModes.notDraggable)
      //   .setPosition({ x: 100, y })
      //   .setBackground((bg) => bg.setFillStyle(emptyFill).setStrokeStyle(emptyLine))
      //   .setTextFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 100) }));

      // xAxisList[index].onScaleChange((start, end) => {
      //   waterMark.setPosition({ x: (start + end) / 2, y });
      // });

      globalData.drawArtifactFunction[signalId] = (artifact) => {
        if (artifact.removed) return;
        const chart = chartsList[signalId];
        const height = ranges[signalId].max - ranges[signalId].min;
        const figure = drawArtifactEventFigure(
          chart,
          yAxisList[signalId],
          chartsList[signalId].getDefaultAxisX(),
          signalLines[signalId].scale,
          ranges[signalId].min,
          '',
          artifact.start,
          artifact.end,
          height,
          true,
        )
          .setFillStyle(
            new SolidFill({
              color: artifactColors[artifact.Type] || ColorRGBA(0, 0, 0, 80),
            }),
          )
          .setStrokeStyle(strokeStyle);
        const mouseMoveEvent = (t, e) => {
          setHoverElement({
            position: { x: e.pageX, y: e.pageY },
            text: 'Artifact',
            size: { start: artifact.start, end: artifact.end },
            isArtifact: true,
            id: artifact.id,
            type: artifact.Type,
            status: artifact.status,
          });
          splineSeries.setCursorEnabled(false);
          figure.setStrokeStyle(
            new SolidLine({
              fillStyle: new SolidFill({ color: ColorHEX('#ffc107') }),
              thickness: 3,
            }),
          );
        };
        figure.onMouseMove(mouseMoveEvent);
        figure.onMouseLeave((t, e) => {
          figure.setStrokeStyle(
            new SolidLine({
              fillStyle: new SolidFill({ color: ColorRGBA(0, 0, 0, 241) }),
              thickness: 1,
            }),
          );
        });

        if (artifact.Type === 'ExcludedArtifact') {
          const [resizeElementFront, resizeElementBack] = addResizers(
            chart,
            true,
            artifacts,
            (a) => {
              globalData.artifacts = a;
              setArtifacts(a);
            },
            artifact.start,
            artifact.end,
            ranges[signalId].min,
            height,
            signalLines[signalId].scale,
            signalId,
            '',
            artifact.id,
            figure,
            zoomIntervalGlobal,
            addHistoryEvent,
          );
          globalData.artifactsList[artifact.id] = {
            resizeElementFront,
            resizeElementBack,
            figure,
          };
          addArtifactMoving(artifact, setArtifacts, figure, chart, splineSeries, setSelectedArtifact, addHistoryEvent, signalId);
        }
      };

      artifacts
        .filter((a) => a.signalId === signalId)
        .forEach((artifact) => {
          globalData.drawArtifactFunction[signalId](artifact);
        });

      const eventTopCustom = {
        ...eventTop,
        ...globalData.eventTopCustom[signalId],
      };

      const idAirflowSignal = signalData.Type === 'Airflow';
      const isPulseSignal = signalData.Type === 'Pulse';
      globalData.drawEventFunction[signalId] = (event, signalId) => {
        if (event.removed) return;
        const start = event.startTimeMicro;
        const end = event.endTimeMicro;
        const type = event.Type;
        if (type !== 'CandidateEvent' && checkArtifactOverlaping(start, end, signalId, null)) return;

        const range = globalData.chartRanges[signalId];
        const height = range.max - range.min;
        const y = (event.yPos || eventTopCustom[event.type] || 0.5) * height + range.min;

        let h = (figureHeight * height) / 100;
        if (event.type === 'RERA' || event.type === 'CheyneStokes') h = h / 2;
        // if (lastPolygonFigure) lastPolygonFigure?.dispose();
        const text = event.customName || type;
        lastPolygonFigure = drawEventFigure(
          chartsList[signalId],
          yAxisList[signalId],
          chartsList[signalId].getDefaultAxisX(),
          signalLines[signalId].scale,
          y,
          text,
          start,
          end,
          h,
        )
          .setFillStyle(
            new SolidFill({
              color: ColorHEX(globalData.eventColors[type] || '#f00000a0'),
            }),
          )
          .setStrokeStyle(strokeStyle);
        const t = globalData.lastTextElement;
        const t2 = lastPolygonFigure;
        const id = event.id;
        visibleEventPolygons.push({
          figure: lastPolygonFigure,
          textFigure: t,
          id,
        });
        const size = { start, end, h, y };
        let [resizeElementFront, resizeElementBack] = [null, null];

        [resizeElementFront, resizeElementBack] = addResizers(
          chart,
          false,
          events,
          setEvents,
          start,
          end,
          y,
          h,
          splineSeries.scale,
          signalId,
          type,
          id,
          t2,
          zoomIntervalGlobal,
          addHistoryEvent,
          t,
        );
        resizeElements.push(resizeElementFront);
        resizeElements.push(resizeElementBack);

        if (event.id === searchedEventId) {
          t2.setStrokeStyle(
            new SolidLine({
              fillStyle: new SolidFill({ color: ColorHEX('#ffc107') }),
              thickness: 3,
            }),
          );
          setTimeout(() => {
            t2.setStrokeStyle(
              new SolidLine({
                fillStyle: new SolidFill({ color: ColorRGBA(0, 0, 0, 241) }),
                thickness: 1,
              }),
            );
          }, [2000]);
        }

        const click = (button, e) => {
          if (globalData.eventPressed && Math.abs(e.clientX - globalData.eventPressed.clientX) > 2) {
            const newPosition = translatePoint(
              chart.engine.clientLocation2Engine(e.clientX, e.clientY),
              chart.engine.scale,
              splineSeries.scale,
            );
            const diff = newPosition.x - globalData.eventPressed.position.x;
            //moveEvent(id, signalId, type, diff, diff, globalData.eventPressed.points, t2, "", chart);
            moveEvent(id, signalId, events, setEvents, type, diff, diff, globalData.eventPressed.points, t2, '', chart);

            globalData.eventPressed = null;
            return;
          }
          globalData.eventPressed = null;
          if (draggingOutsideTimer) {
            clearInterval(draggingOutsideTimer);
            draggingOutsideTimer = null;
          }
          if (!idAirflowSignal && type !== 'SnoreEvent') {
            if (Date.now() - globalData.lastDragOutsideTime < 2000) return;
            globalData.lastTextElement = t;
            lastPolygonFigure = t2;

            setEventSizeUpdated(id, signalId, y, h);
            eventWasClicked = true;
            setSelectedEvent({
              text: type,
              newText: type,
              id,
              signalId,
              added: event.added,
              customCharacteristics: event.customCharacteristics,
              edit: true,
            });
            setEventEditMode(true);
            setModalOpen(signalId);
            setHoverElement(null);
          }
        };
        lastPolygonFigure.onMouseClick(click);
        if (idAirflowSignal || (isPulseSignal && ['Tachycardia', 'Bradycardia'].includes(event.Type))) {
          lastPolygonFigure.onMouseDown((b, e1) => {
            if (e1.button === 2) {
              setEventEditMode(true);

              const e = document.getElementById('eventContextMenu');
              let y = e1.pageY;
              const h = e.clientHeight || 280;
              if (y + h + 50 > window.innerHeight) {
                y -= y + h + 50 - window.innerHeight;
              }
              e.style.top = y + 'px';
              let x = e1.pageX;
              if (window.innerWidth - e1.pageX - 130 < 30) {
                x += window.innerWidth - 30 - e1.pageX - 130;
              }

              e.style.left = x + 10 + 'px';

              e.classList.add('visible');
              setSelectedEvent({
                text: type,
                newText: type,
                id,
                signalId,
                added: event.added,
                customCharacteristics: event.customCharacteristics,
                lastTextElement: t,
                lastPolygonFigure: t2,
                eventRightClick: true,
                y,
                h,
              });
            }
          });
        }
        const mouseMoveEvent = (t, e) => {
          t2.setStrokeStyle(
            new SolidLine({
              fillStyle: new SolidFill({ color: ColorHEX('#ffc107') }),
              thickness: 3,
            }),
          );
          if (globalData.eventPressed) {
            hideEventHoverChart();
            setHoverElement(null);

            return;
          }

          if (!globalData.eventChartData || globalData.eventChartData.eventId !== id) {
            globalData.eventChartData = geteventChartData(signalId, id, start, end);

            splineSeries.setCursorEnabled(false);
          }
          setHoverElement({
            position: { x: e.pageX, y: e.pageY },
            text,
            size,
            status: event.status,
            event: event,
            customCharacteristics: event.customCharacteristics,
          });
        };
        lastPolygonFigure.onMouseMove(mouseMoveEvent);
        t2.onMouseLeave((t, e) => {
          if (e) {
            const curLocationAxis = translatePoint(
              chart.engine.clientLocation2Engine(e.clientX, e.clientY),
              chart.engine.scale,
              splineSeries.scale,
            );
            if (curLocationAxis.x >= start + 1000 && curLocationAxis.x <= end - 1000 && Math.abs(curLocationAxis.y - y) <= size.h) {
              return;
            }
          }
          t2.setStrokeStyle(
            new SolidLine({
              fillStyle: new SolidFill({ color: ColorRGBA(0, 0, 0, 241) }),
              thickness: 1,
            }),
          );
          globalData.eventChartData = null;
          setHoverElement(null);
          splineSeries.setCursorEnabled(true);
        });
        lastPolygonFigure.onMouseDragStart((t1, e) => {
          if (e?.button !== 0) return;
          const figurePoints = t1.getDimensions();
          const pixelLocationEnd = translatePoint(
            { x: figurePoints[1].x, y: 50 },
            {
              x: chart.getDefaultAxisX(),
              y: chart.getDefaultAxisY(),
            },
            chart.pixelScale,
          );
          const pixelLocationStart = translatePoint(
            { x: figurePoints[0].x, y: 50 },
            {
              x: chart.getDefaultAxisX(),
              y: chart.getDefaultAxisY(),
            },
            chart.pixelScale,
          );
          globalData.eventPressed = {
            clientX: e.clientX,
            position: translatePoint(chart.engine.clientLocation2Engine(e.clientX, e.clientY), chart.engine.scale, splineSeries.scale),
            rightBorderDist: chart.engine.engineLocation2Client(pixelLocationEnd.x, pixelLocationEnd.y).x - e.clientX,
            leftBorderDist: chart.engine.engineLocation2Client(pixelLocationStart.x, pixelLocationStart.y).x - e.clientX,
            figure: t2,
            textFigure: t,
            points: figurePoints,
            end: zoomIntervalGlobal.end,
            id,
            signalId,
            type,
          };
          hideEventHoverChart();
          setHoverElement(null);
        });
        lastPolygonFigure.onMouseDrag((t, e) => {
          if (globalData.eventPressed) {
            const newPosition = translatePoint(
              chart.engine.clientLocation2Engine(e.clientX, e.clientY),
              chart.engine.scale,
              splineSeries.scale,
            );
            const diff = newPosition.x - globalData.eventPressed.position.x;
            if (!draggingOutsideTimer)
              t2.setDimensions(
                globalData.eventPressed.points.map((p) => ({
                  x: p.x + diff,
                  y: p.y,
                })),
              );
            globalData.setHoverElement({
              position: { x: e.pageX, y: e.pageY },
              size: {
                start: event.startTimeMicro + diff,
                end: event.endTimeMicro + diff,
              },
              isEventDrawing: true,
              onRight: false,
            });
          }
        });

        if (!globalData.allEventsList[type]) globalData.allEventsList[type] = [];
        globalData.allEventsList[type].push({
          type,
          text,
          signalIndex,
          start,
          end,
          figure: lastPolygonFigure,
          resizeElementFront,
          resizeElementBack,
          textFigure: t,
          id,
          signalId,
        });
        lastPolygonFigure = null;
        globalData.lastTextElement = null;
      };
      if (true /*events[signalId] && events[signalId].length*/) {
        events[signalId].forEach((event) => {
          if (event.startTimeMicro > zoomIntervalGlobal.end) return;

          globalData.drawEventFunction[signalId](event, signalId);
        });
        const canDrawEvents = events[signalId].length > 0;
        chart.onSeriesBackgroundMouseDragStart((_, event, button) => {
          console.log('drag start');
          lastDrawingPolygonFigure = null;
          if (button !== 0) return;
          if (!canDrawEvents && !window.artifactDrawing) return;
          globalData.lastTextElement = null;
          const curLocationAxis = translatePoint(
            chart.engine.clientLocation2Engine(event.clientX, event.clientY),
            chart.engine.scale,
            splineSeries.scale,
          );
          startPoint = curLocationAxis;
          currentChartHeight = globalData.chartRanges[signalId].max - globalData.chartRanges[signalId].min;
        });
        chart.onSeriesBackgroundMouseDrag((_, event, button) => {
          if (button !== 0) return;
          if (!canDrawEvents && !window.artifactDrawing) return;
          const curLocationAxis = translatePoint(
            chart.engine.clientLocation2Engine(event.clientX, event.clientY),
            chart.engine.scale,
            splineSeries.scale,
          );
          globalData.lastTextElement?.dispose();
          lastDrawingPolygonFigure?.dispose();
          if (curLocationAxis.x > 0 && curLocationAxis.x < zoomIntervalGlobal.start) {
            setZoomInterval({
              start: curLocationAxis.x,
              end: zoomIntervalGlobal.end,
            });
            globalData.firstChart.setInterval(curLocationAxis.x, zoomIntervalGlobal.end);
          }
          const mouseOnRightSide = curLocationAxis.x > startPoint.x;
          setHoverElement({
            position: { x: event.pageX, y: event.pageY },
            text: 'Artifact',
            size: {
              start: Math.min(curLocationAxis.x, startPoint.x),
              end: Math.max(curLocationAxis.x, startPoint.x),
            },
            isEventDrawing: true,
            onRight: mouseOnRightSide,
          });
          if (window.artifactDrawing) {
            const y = (startPoint.y + curLocationAxis.y) / 2;
            const h = currentChartHeight * 0.95;
            if (mouseOnRightSide)
              lastDrawingPolygonFigure = drawEventFigure(
                chart,
                axisY,
                xAxisList[index],
                splineSeries.scale,
                y,
                '',
                startPoint.x,
                curLocationAxis.x,
                h,
                true,
              )
                .setFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 80) }))
                .setStrokeStyle(strokeStyle);
            else
              lastDrawingPolygonFigure = drawEventFigure(
                chart,
                axisY,
                xAxisList[index],
                splineSeries.scale,
                y,
                '',
                curLocationAxis.x,
                startPoint.x,
                h,
                true,
              )
                .setFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 80) }))
                .setStrokeStyle(strokeStyle);
            return;
          }

          const y = (startPoint.y + curLocationAxis.y) / 2;
          const h = (figureHeight * currentChartHeight) / 100;
          if (mouseOnRightSide)
            lastDrawingPolygonFigure = drawEventFigure(
              chart,
              axisY,
              xAxisList[index],
              splineSeries.scale,
              y,
              '',
              startPoint.x,
              curLocationAxis.x,
              h,
              true,
            )
              .setFillStyle(fillStyle)
              .setStrokeStyle(strokeStyle);
          else
            lastDrawingPolygonFigure = drawEventFigure(
              chart,
              axisY,
              xAxisList[index],
              splineSeries.scale,
              y,
              '',
              curLocationAxis.x,
              startPoint.x,
              h,
              true,
            )
              .setFillStyle(fillStyle)
              .setStrokeStyle(strokeStyle);
        });

        chart.onSeriesBackgroundMouseDragStop((_, event, button) => {
          if (!canDrawEvents && !window.artifactDrawing) return;
          const width = document.body.getBoundingClientRect().width;
          //if (event.clientX > 1400) event.stopPropagation();
          // if (event.clientX > 1500 && event.pressure) return;
          const mouseOutsideRight = event.clientX > width - 30;
          const mouseOutsideLeft = event.clientX < 56;
          const curLocationAxis = translatePoint(
            chart.engine.clientLocation2Engine(event.clientX, event.clientY),
            chart.engine.scale,
            splineSeries.scale,
          );
          currentDrawingSignalId = signalId;
          if (mouseOutsideRight) {
            draggingOutsideStart(signalId, curLocationAxis.y, true, false, window.artifactDrawing);

            return;
          }
          if (mouseOutsideLeft) {
            draggingOutsideStart(signalId, curLocationAxis.y, false, false, window.artifactDrawing);

            return;
          }
          if (!mouseOutsideRight && !mouseOutsideLeft && button !== 0) return;

          const y = (startPoint.y + curLocationAxis.y) / 2;
          let start = startPoint.x;
          let end = curLocationAxis.x;
          if (curLocationAxis.x < startPoint.x) {
            end = startPoint.x;
            start = curLocationAxis.x;
          }

          if (Math.abs(end - start) < 2) return;

          setHoverElement(null);
          lastDrawingPolygonFigure?.dispose();

          if (window.artifactDrawing) {
            addArtifact(start, end, signalId);
            return;
          }
          lastDrawingPolygonFigure = drawEventFigure(
            chart,
            axisY,
            xAxisList[index],
            splineSeries.scale,
            y,
            '',
            start,
            end,
            window.artifactDrawing ? height : (figureHeight * height) / 100,
          )
            .setFillStyle(fillStyle)
            .setStrokeStyle(strokeStyle);
          tempEvent = {
            signalIndex,
            start,
            end,
            figure: lastPolygonFigure,
          };
          setEventSize({ start, end, figureHeight, y });
          setModalOpen(signalId);
          setEventEditMode(false);
        });
      }
      if (lineSteps[signalId] && lineSteps[signalId].length > 1) {
        // lineSteps[signalId].sort((a, b) => a.value - b.value);
        splineSeries.setStrokeStyle(
          new SolidLine({
            thickness: 2,
            fillStyle: new PalettedFill({
              lookUpProperty: 'x',
              lut: new LUT({
                interpolate: false,
                steps: lineSteps[signalId],
              }),
            }),
          }),
        );
      }

      if (chartAreasData[signalId]) {
        const areaSeries = chart.addAreaRangeSeries({
          xAxis: xAxisList[signalId],
        });
        if (signalId === globalData.pulseSignalId) {
          areaSeries.setHighFillStyle(new SolidFill({ color: ColorHEX('#07a007aa') }));
          areaSeries.setLowFillStyle(new SolidFill({ color: ColorHEX('#57a057aa') }));
          areaSeries.setHighStrokeStyle(new SolidFill({ color: ColorHEX('#07a007aa') }));
        } else {
          areaSeries.setHighFillStyle(new SolidFill({ color: ColorHEX('#bad6f2e0') }));
          areaSeries.setLowFillStyle(new SolidFill({ color: ColorHEX('#bad6f2e0') }));
          areaSeries.setHighStrokeStyle(new SolidFill({ color: ColorHEX('#bad6f2e0') }));
        }
        //areaSeries.setMaxPointCount(100);
        areaSeries.setCursorEnabled(false);
        areaSeries.setLowStrokeStyle(transparentLine);
        areaSeries.add(chartAreasData[signalId]);
      }
      //synchronizeAxisIntervals(...xAxisList, firstAxis);

      return rowIndex;
    },
    [
      addArtifact,
      addPolygonEvent,
      artifacts,
      chartSizes,
      drawSlider,
      eventTop,
      addHistoryEvent,
      events,
      setEventSizeUpdated,
      setHoverElement,
      draggingOutsideStart,
    ],
  );

  const redrawSignal = useCallback(
    (signalId, redraw = false) => {
      chartDrawing = true;
      const data = chartSignalDrawData[signalId];
      // chartSignalDrawData[signalData.signalId] = {
      //   index,
      //   signalIndex,
      //   rowIndex,
      //   topAxisSize,
      //   rowSizeSum,
      // };
      const signalData = signalsData.find((s) => s.SignalId === signalId);
      const mainData = chartSignalDrawData.main;
      drawSignal(redraw, data.index, data.signalIndex, signalData, data.rowIndex, mainData.topAxisSize, mainData.rowSizeSum);
      chartDrawing = false;
    },
    [drawSignal],
  );

  const loadBigSignals = useCallback(
    (signalsToLoad) => {
      let l = signalsToLoad.length;
      const newRanges = { ...chartRanges };
      var myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + globalData.token);
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      signalsToLoad.forEach((s) => {
        fetch(
          `${host}/sleepstudy/api/signalassinglesegment?SampleRate=${s.rate}&signalId=${s.signalId}&startTime=${timeIntervalString.StartTime}&endTime=${timeIntervalString.EndTime}`,
          requestOptions,
        )
          .then((response) => {
            return response.json();
          })
          .then((signal) => {
            const i = s.i;
            const signalId = s.signalId;
            const signalObject = signalIdsData[i];
            const rate = s.rate;
            const chartData = {
              i,
              SignalId: signalObject.SignalId,
              data: signal.Points.map((point, i) => ({
                x: (i * 1000) / rate,
                y: point,
              })),
              Type: signalObject.Type,
              name: signalObject.Type + (signalObject.Specification ? ' ' + signalObject.Specification : ''),
              Specification: signalObject.Specification,
              rate: signal.SampleRate,
            };

            // if (chartData.Type === "Pulse") chartData.data = interpolatePoints(chartData.data);
            signalsData[i] = chartData; //.sort((a, b) => a.i - b.i);
            globalData.loadedChartsData[signalId] = chartData;
            if (signalChartsList[signalId]) {
              signalChartsList[signalId].clear();
              signalChartsList[signalId].add(chartData.data);
            } else {
              console.log('error', signalId);
            }
            if (signalObject.Type === 'SPO2') {
              if (!minSpo2Value) {
                let min = 80;
                chartData.data.forEach((p) => {
                  if (p.y < min && p.y > 75) min = p.y;
                });
                minSpo2Value = Math.floor(min / 10) * 10;
                minSpo2Value = minSpo2Value < 75 ? 75 : minSpo2Value;
                if (zoomBandChart) zoomBandChart.getDefaultAxisY().setMouseInteractions(true).setInterval(minSpo2Value, 100, true, true);
              }

              const autoRange = { min: minSpo2Value, max: 100 }; // getAutoScale(signalId, null);
              newRanges[signalId] = autoRange;
              spoLabels = spoLabels.concat(getMinMaxValues(chartData.data));
            }
            if (signalObject.Type === 'Pulse') {
              pulseLabels = pulseLabels.concat(getMinMaxValues(chartData.data));
            }

            l--;
            if (l === 0) {
              setChartRanges(newRanges);
              globalData.chartRanges = newRanges;
              //drawPulseValues(newRanges);
            }
          });
      });
    },
    [chartRanges, setChartRanges],
  );

  const drawCharts = useCallback(() => {
    if (!signalsData || !events) return;
    chartDrawing = true;
    console.log('redraw');
    Object.values(globalData.allEventsList).forEach((evList) => {
      evList.forEach((ev) => {
        if (!ev.figure) return;
        ev.figure.offMouseLeave();
        ev.figure.offMouseMove();
        ev.figure?.dispose();
      });
    });
    visibleEventTextElements.forEach((figure) => {
      figure?.dispose();
    });
    visibleEventPolygons = [];
    visibleEventTextElements = [];
    signalLines = {};
    globalData.allEventsList = {};
    const chartNumber = selectedChartIds.length;
    const topAxisSize = Math.floor((window.innerHeight - headerHeight) / 50);
    if (dashboardObject.current) {
      dashboardObject.current.forEachChart((chart) => {
        const series = chart.getSeries();
        series.forEach((s) => {
          s.dispose();
        });
        chart.forEachAxis((axis) => {
          axis.dispose();
        });
        chart?.dispose();
      });
      dashboardObject.current?.dispose();
    }
    const license =
      window.location.hostname === 'localhost'
        ? {}
        : {
            license: '0001-0b1e8-a309c-d41d0-be04d-55814-5818d-6d839-faac0-f6c57-532b3-eaf49-00020-10aa8-d0100-0048b-9f21f',
          };
    const rowSizeSum = selectedChartIds
      .map((id) => chartSizes[id])
      .reduce((prev, curr) => {
        return prev + curr;
      }, 0);
    globalData.rowSizeSum = rowSizeSum;
    dashboardObject.current = lightningChart(license)
      .Dashboard({
        theme: Themes.lightNew,
        numberOfColumns: 1,
        container: 'chartContainer',
        numberOfRows: Math.floor(4 * rowSizeSum + 23 * chartNumber + 4 * topAxisSize * rowSizeSum + rowSizeSum + 14),
        height: window.innerHeight - headerHeight,
        margin: { top: 50 },
      })
      .setSplitterStyle(new SolidLine({ thickness: 0 }));
    const dashboard = dashboardObject.current;
    resizeElements = [];
    globalData.eventChartData = null;
    xAxisList = [];
    yAxisList = {};
    signalChartsList = {};
    let index = 0;
    const chart0 = dashboard
      .createChartXY({
        columnIndex: 0,
        columnSpan: 1,
        rowIndex: 23 * chartNumber,
        rowSpan: Math.floor(rowSizeSum * 4),
        theme: Themes.lightNew,
        defaultAxisX: {
          opposite: true,
        },
      })
      .setTitleMarginBottom(0)
      .setTitle('')
      .setTitleFillStyle(emptyFill)
      .setPadding({
        right: 10,
        top: 16,
        bottom: 0,
        left: 54,
        //bottom: i === 0 ? 0 : -30,
      })
      .setTitleMarginTop(0);
    firstAxis = chart0
      .getDefaultAxisX()
      .setOverlayStyle(axisXStyleHighlight)
      .setMouseInteractions(false)
      .setNibOverlayStyle(axisXStyleHighlight)
      .setScrollStrategy(undefined)
      .setThickness(20)
      .setNibLength(0)
      .setTickStrategy(AxisTickStrategies.DateTime, (dateTimeTicks) =>
        dateTimeTicks.setDateOrigin(dateOrigin).setGreatTickStyle(emptyTick),
      );
    chart0.setAutoCursor((cursor) => {
      cursor
        .setResultTableAutoTextStyle(false)
        .disposeTickMarkerX()
        .disposeTickMarkerY()
        .setTickMarkerXAutoTextStyle(false)
        .setTickMarkerYAutoTextStyle(false)
        .setGridStrokeYStyle(emptyLine);
    });
    firstAxis
      .addCustomTick()
      .setTickLength(60)
      .setTextFormatter((position, customTick) => '');
    chart0
      .getDefaultAxisY()
      .setNibLength(0)
      .setTickStrategy(AxisTickStrategies.Numeric, (tickStrategy) =>
        tickStrategy
          .setMajorFormattingFunction((tickPosition) => {
            return '';
          })
          .setMinorFormattingFunction((tickPosition) => {
            return '';
          })
          .setMinorTickStyle((tickStyle) => tickStyle.setGridStrokeStyle(emptyLine)),
      );

    const offset = new Date().getTimezoneOffset();
    firstAxis
      .addCustomTick(UIElementBuilders.AxisTick)
      .setValue(0)
      .setTextFormatter((_) => new Date(globalData.dateOriginTime - offset * 60000).toISOString().slice(11, 16));

    const splineSeries1 = chart0.addLineSeries({
      xAxis: firstAxis,
      dataPattern: {
        pattern: 'ProgressiveX',
        regularProgressiveStep: true,
      },
    });
    splineSeries1.setMaxPointCount(1000);
    splineSeries1.add(signalsData[0].data.map((t) => ({ x: t.x, y: 0 })));
    splineSeries1.setStrokeStyle(
      new SolidLine({
        thickness: 0,
      }),
    );
    let rowIndex = Math.floor(4 * rowSizeSum) + 23 * chartNumber;
    chartSignalDrawData = {
      main: {
        rowSizeSum,
        topAxisSize,
      },
    };
    zoomIdActual = selectedChartIds.includes(zoomId) ? zoomId : selectedChartIds[0];
    selectedCharts
      .map((i) => signalsData[i])
      .forEach((signalData, signalIndex) => {
        chartSignalDrawData[signalData.SignalId] = {
          index,
          signalIndex,
          rowIndex,
          topAxisSize,
          rowSizeSum,
        };
        rowIndex = drawSignal(true, index, signalIndex, signalData, rowIndex, topAxisSize, rowSizeSum);

        index++;
      });

    drawPulseValues();

    Object.keys(globalData.allEventsList).forEach((eventType) => {
      if (!visibleEvents.includes(eventType)) {
        globalData.allEventsList[eventType].forEach((e) => {
          e.figure?.dispose();
          e.textFigure?.dispose();
          e.resizeElementFront?.dispose();
          e.resizeElementBack?.dispose();
        });
      }
    });

    const chartLast = dashboard
      .createChartXY({
        columnIndex: 0,
        columnSpan: 1,
        rowIndex: Math.floor(4 * rowSizeSum + 23 * chartNumber + 4 * topAxisSize * rowSizeSum + 14),
        rowSpan: Math.floor(rowSizeSum),
        theme: Themes.lightNew,
      })
      .setTitleMarginBottom(0)
      .setTitle('')
      .setTitleFillStyle(emptyFill)
      .setPadding({
        right: 10,
        left: 20,
        top: 0,
        bottom: 10,
        //bottom: i === 0 ? 0 : -30,
      })
      .setTitleMarginTop(0);
    chartLast.getDefaultAxisY().setMouseInteractions(false);
    const lastAxis = chartLast
      .getDefaultAxisX()
      .setOverlayStyle(axisXStyleHighlight)
      .setMouseInteractions(false)
      .setNibOverlayStyle(axisXStyleHighlight)
      .setScrollStrategy(undefined)
      .setThickness(20)
      .setNibLength(0)
      .setTickStrategy(AxisTickStrategies.DateTime, (dateTimeTicks) =>
        dateTimeTicks.setDateOrigin(dateOrigin).setGreatTickStyle(emptyTick),
      );
    chartLast.setAutoCursor((cursor) => {
      cursor
        .setResultTableAutoTextStyle(false)
        .disposeTickMarkerX()
        .disposeTickMarkerY()
        .setTickMarkerXAutoTextStyle(false)
        .setTickMarkerYAutoTextStyle(false)
        .setGridStrokeYStyle(emptyLine);
    });
    lastAxis
      .addCustomTick(UIElementBuilders.AxisTick)
      .setValue(0)
      .setTextFormatter((_) => new Date(globalData.dateOriginTime - offset * 60000).toISOString().slice(11, 16));
    const splineSeriesLast = chartLast.addLineSeries({
      xAxis: lastAxis,
      dataPattern: {
        pattern: 'ProgressiveX',
        regularProgressiveStep: true,
      },
    });
    splineSeries1.setMaxPointCount(1000);
    splineSeriesLast.add(signalsData[0].data);
    splineSeriesLast.setStrokeStyle(
      new SolidLine({
        thickness: 0,
      }),
    );
    const allEventsChartAxis = drawAllEventsChart();
    setFirstChart(xAxisList[0]);
    //setChartRanges(ranges);
    synchronizeAxisIntervals(...xAxisList, firstAxis, lastAxis);
    xAxisList[0].setInterval(zoomIntervalGlobal.start, zoomIntervalGlobal.end);
    // lastAxis.setInterval(
    //   0,
    //   (signalsData[0].data.length * 1000) / signalsData[0].rate,
    // );
    if (loading) {
      chartsDataUpdated++;
      setLoading(false);
    }

    if (signalsToLoad.length) {
      loadBigSignals(signalsToLoad);
      signalsToLoad = [];
    } else {
      // drawPulseValues();
    }
    const listElement = document.getElementById('event_selector_list2');
    listElement.style.visibility = 'visible';
    chartDrawing = false;
    setTimeout(() => {
      drawLSLabels();
    }, 3000);
    //setZoomInterval(zo);
    //setSignalsData(null);
  }, [chartSizes, loadBigSignals, events, selectedCharts, drawSignal]);

  useEffect(() => {
    if (zoomId !== zoomSignalId && !dataLoading) {
      zoomId = zoomSignalId;
      drawCharts();
    }
    //if (dashboardObject.current && globalData.loadedChartsData[zoomSignalId]) drawSlider(dashboardObject.current, zoomSignalId, globalData.loadedChartsData[zoomSignalId], chartColors[zoomSignalId]);
  }, [zoomSignalId, drawCharts, dataLoading]);

  var timeoutId = useRef(null);
  const handleResize = useCallback(() => {
    if (redrawCharts && signalsData.length && events && Object.keys(chartRanges).length && !dataLoading) {
      clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(function () {
        redrawCharts = false;
        drawCharts();
        setDataLoading(false);

        rowHeight = document.querySelector('.chartNames .chartName').clientHeight;
        const top_el = document.getElementById('chart_top_left');
        if (top_el) {
          const rect = top_el.getBoundingClientRect();
          const top = rect.top - 10;
          const stage_el = document.getElementById('sleepStage');
          globalData.chartLeftBorder = rect.right + 60;
          globalData.chartTopBorder = top;
          if (stage_el) {
            stage_el.style.top = top + 'px';
            stage_el.style.height = `calc(100% - ${top + 20}px)`;
          }
        }
      }, 800);
    }
  }, [events, artifacts, drawCharts, setDataLoading, dataLoading, drawLSLabels, chartRanges]);

  useEffect(() => {
    if (patientId) {
      loadStudyData(setStudyData, studyId, patientId);
      getReportId(patientId, guid);
    }
  }, [setStudyData, studyId, patientId]);

  const drawAllEventsChart = useCallback(() => {
    const dashboard = dashboardObject.current;
    if (!dashboard || !selectedChartIds?.length) return;
    const chartNumber = selectedChartIds.length;
    const topAxisSize = Math.floor((window.innerHeight - headerHeight) / 50);
    const rowIndex = Math.floor(4 * (topAxisSize * globalData.rowSizeSum + 3 * chartNumber)) + 4;
    const yCooridantes = {};
    let y = 1;
    //const visibleEvents = globalData.firstChartEvents.filter((t) => t.show).map((t) => t.type);
    let chartVisibleEvents = Object.keys(globalData.allEventsList).filter((t) => visibleEvents.includes(t));
    if (chartVisibleEvents.length < 2) chartVisibleEvents = globalData.allEventTypesList;
    chartVisibleEvents.forEach((eventType) => {
      yCooridantes[y] = eventType; //.replace("Oximetry", "");
      y++;
    });

    if (allEventsChart) allEventsChart.dispose();
    allEventsChart = dashboard
      .createChartXY({
        columnIndex: 0,
        columnSpan: 1,
        rowIndex: 0,
        rowSpan: 15 * chartNumber,
        theme: Themes.lightNew,
      })
      .setTitleMarginBottom(0)
      .setTitle('')
      .setTitleFillStyle(emptyFill)
      .setPadding({
        right: 15,
        left: 50,
        top: 0,
        bottom: 10,
        //bottom: i === 0 ? 0 : -30,
      })
      .setTitleMarginTop(0);
    console.log('redraw evens chart');
    const allEventsChartAxis = allEventsChart
      .getDefaultAxisX()
      .setOverlayStyle(axisXStyleHighlight)
      //.setMouseInteractions(false)
      .setNibOverlayStyle(axisXStyleHighlight)
      .setScrollStrategy(undefined)
      .setThickness(20)
      .setInterval(0, globalData.dateEndTime - globalData.dateOriginTime)
      .setNibLength(0)
      .setTickStrategy(AxisTickStrategies.DateTime, (dateTimeTicks) =>
        dateTimeTicks.setDateOrigin(dateOrigin).setGreatTickStyle(emptyTick),
      );
    const offset = new Date().getTimezoneOffset();

    const customTick = allEventsChartAxis
      .addCustomTick(UIElementBuilders.AxisTick)
      .setValue(0)
      .setTextFormatter((_) => new Date(globalData.dateOriginTime - offset * 60000).toISOString().slice(11, 16));
    customTick.setMarker((tickMarker) =>
      tickMarker.setTextFont(
        new FontSettings({
          size: 10,
        }),
      ),
    );
    const axisY = allEventsChart
      .getDefaultAxisY()
      .setStrokeStyle(axisYStrokeStyles[0])
      .setTitle('')
      .setInterval(0, 10)
      .setOverlayStyle(axisYStylesHighlight[0])
      .setNibOverlayStyle(axisYStylesHighlight[0])
      // .setInterval(2, y * 2 - 2)
      .setNibLength(10)
      .setTitleFont(
        new FontSettings({
          size: 11,
          family: 'Arial, Helvetica, sans-serif',
          weight: 'bold',
          style: 'italic',
        }),
      )
      .setScrollStrategy(AxisScrollStrategies.regressive)
      .setTickStrategy(AxisTickStrategies.Numeric, (tickStrategy) =>
        tickStrategy
          .setMajorFormattingFunction((tickPosition) => {
            return ''; //yCooridantes[tickPosition]?.replace("Oximetry", "") || "";
          })
          .setMinorFormattingFunction((tickPosition) => {
            // return tickPosition.toString();
            return '';
          })
          .setMajorTickStyle((tickStyle) => tickStyle.setGridStrokeStyle(emptyLine).setLabelAlignment(1))
          .setMinorTickStyle((tickStyle) => tickStyle.setGridStrokeStyle(emptyLine).setLabelAlignment(1)),
      )
      .setTickStrategy(AxisTickStrategies.Numeric);

    // allEventsChart
    //   .addPolygonSeries()
    //   .add([
    //     { x: 0, y: 1 },
    //     { x: 1000, y: 1 },
    //     { x: 1000, y: 5 },
    //     { x: 0, y: 5 },
    //   ])
    //   .setFillStyle(new SolidFill({ color: ColorHEX("#00f000a0") }))
    //   .setStrokeStyle(strokeStyle);

    const splineSeriesLast = allEventsChart.addLineSeries({
      xAxis: allEventsChartAxis,
      yAxis: axisY,
      dataPattern: {
        pattern: 'ProgressiveX',
        regularProgressiveStep: true,
      },
    });

    // const customTick = axisY.addCustomTick(UIElementBuilders.AxisTick);
    // Object.keys(yCooridantes).forEach((y) => {
    //   axisY
    //     .addCustomTick(UIElementBuilders.AxisTick)
    //     // .setLabelAlignment(-1)
    //     .setValue(y)
    //     .setGridStrokeStyle(emptyLine)
    //     .setTextFormatter((_) => yCooridantes[y].replace("Oximetry", ""));
    // });
    const polygonSeries = allEventsChart.addPolygonSeries();
    const onMouseOver = (e, type) => {
      setHoverElement({
        position: { x: e.pageX, y: e.pageY },
        size: { start: '1', end: '2' },
        isEventDrawing: true,
        customText: type,
        onRight: false,
      });
    };
    // console.log(eventSignalIds);
    // console.log(events);
    // console.log(signalIds);
    // console.log(globalData.firstChartEvents);
    let eventsNumber = 0;

    globalData.firstChartEvents.slice(4).forEach((e) => {
      if (!e.show) return;
      const t = signalIds.find((s) => s.Type === e.signalName);
      if (!t) return;
      const signalId = t.SignalId;
      eventsNumber++;
      if (events[signalId]) {
        axisY
          .addCustomTick(UIElementBuilders.AxisTick)
          // .setLabelAlignment(-1)
          .setValue(eventsNumber)
          .setGridStrokeStyle(emptyLine)
          .setTextFormatter((_) => '');
        const type = e.value || e.title;
        const y = eventsNumber;
        events[signalId]
          .filter((ev) => ev.Type === type || ev.customName === type)
          .forEach((event) => {
            if (event.removed) return;
            const start = event.startTimeMicro;
            const end = event.endTimeMicro;

            const pols = polygonSeries
              .setCursorEnabled(false)
              .setHighlightOnHover(false)
              .setHighlightMode(lcjs.HighlightModes.noHighlighting)
              .add([
                { x: start, y: y - 0.4 },
                { x: end, y: y - 0.4 },
                { x: end, y: y + 0.4 },
                { x: start, y: y + 0.4 },
              ])
              .setMouseInteractions(false)
              .setFillStyle(
                new SolidFill({
                  color: ColorHEX(globalData.eventColors[event.Type] || '#f00000a0'),
                }),
              )
              .setStrokeStyle(strokeStyle);
            pols.onMouseMove((t, e) => {
              onMouseOver(e, type);
            });
            pols.onMouseLeave((t, e) => {
              setHoverElement(null);
            });

            // const t = globalData.lastTextElement;
            // const t2 = lastPolygonFigure;
            // const size = { start, end, h, y };
            // const id = event.id;
          });
      }
    });

    allEventsChart.setAutoCursor((cursor) => {
      cursor
        .setResultTableAutoTextStyle(false)
        .disposeTickMarkerX()
        .disposeTickMarkerY()
        .setTickMarkerXAutoTextStyle(false)
        .setTickMarkerYAutoTextStyle(false)
        .setGridStrokeYStyle(emptyLine);
    });
    axisY.setInterval(0.1, eventsNumber);

    if (positionSignal && globalData.firstChartEvents[0].show) {
      eventsNumber++;
      axisY
        .addCustomTick(UIElementBuilders.AxisTick)
        .setValue(eventsNumber)
        .setTextFormatter((_) => '');
      axisY.setInterval(0.1, eventsNumber + 1);
      let i = 0;
      let current = getBodyRotationDirection(positionSignal.data[0].y);
      let currentX = 0;
      const y = eventsNumber;
      const l = positionSignal.data.length - 1;
      for (i = 1; i < positionSignal.data.length; i++) {
        const y1 = positionSignal.data[i].y;
        let c = getBodyRotationDirection(y1);
        const y2 = positionSignal.data[currentX].y;
        const hoverText = globalData.rotationHoverText[y2] || globalData.rotationHoverText['other'];
        if (c !== current || i === l) {
          const pols = polygonSeries
            .setCursorEnabled(false)
            .setHighlightOnHover(false)
            .setHighlightMode(lcjs.HighlightModes.noHighlighting)
            .add([
              { x: positionSignal.data[currentX].x, y: y - 0.4 },
              { x: positionSignal.data[i].x, y: y - 0.4 },
              { x: positionSignal.data[i].x, y: y + 0.4 },
              { x: positionSignal.data[currentX].x, y: y + 0.4 },
            ])
            .setMouseInteractions(false)
            .setFillStyle(
              new SolidFill({
                color: ColorHEX(bodyRotationColor[current] || '#f00000a0'),
              }),
            )
            .setStrokeStyle(strokeStyle);
          const onMouseOver = (e, type) => {
            setHoverElement({
              position: { x: e.pageX, y: e.pageY },
              size: { start: '1', end: '2' },
              isEventDrawing: true,
              customText: type,
              onRight: false,
            });
          };
          pols.onMouseMove((t, e) => {
            onMouseOver(e, hoverText);
          });
          pols.onMouseLeave((t, e) => {
            setHoverElement(null);
          });

          allEventsChart
            .addUIElement(UIElementBuilders.TextBox, splineSeriesLast.scale)
            .setText(current)
            .setDraggingMode(lcjs.UIDraggingModes.onlyHorizontal)
            .setPosition({
              x: (positionSignal.data[currentX].x + positionSignal.data[i].x) / 2,
              y: y,
              z: 1,
            })
            .setMouseInteractions(false)
            .setTextFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 250) }))
            .setTextFont(
              new FontSettings({
                size: 12,
              }),
            )
            .setBackground((background) => background.setFillStyle(emptyFill).setStrokeStyle(emptyLine));
          current = c;
          currentX = i;
        }
      }
    }
    if (globalData.wakeData && globalData.firstChartEvents[1].show) {
      eventsNumber++;
      axisY
        .addCustomTick(UIElementBuilders.AxisTick)
        .setValue(eventsNumber)
        .setTextFormatter((_) => '');
      axisY.setInterval(0.1, eventsNumber + 1);
      let i = 0;
      let current = globalData.wakeData[0].text;
      let currentX = 0;
      const y = eventsNumber;
      const l = globalData.wakeData.length - 1;
      for (i = 1; i < globalData.wakeData.length; i++) {
        const c = globalData.wakeData[i].text;
        // const y2 = positionSignal.data[currentX].y;
        // const hoverText = globalData.rotationHoverText[y2] || globalData.rotationHoverText['other'];
        if (c !== current || i === l) {
          const pols = polygonSeries
            .setCursorEnabled(false)
            .setHighlightOnHover(false)
            .setHighlightMode(lcjs.HighlightModes.noHighlighting)
            .add([
              { x: globalData.wakeData[currentX].startTimeMicro, y: y - 0.4 },
              { x: globalData.wakeData[i].startTimeMicro, y: y - 0.4 },
              { x: globalData.wakeData[i].startTimeMicro, y: y + 0.4 },
              { x: globalData.wakeData[currentX].startTimeMicro, y: y + 0.4 },
            ])
            .setMouseInteractions(false)
            .setFillStyle(
              new SolidFill({
                color: ColorHEX(wakeDataColor[current] || '#f00000a0'),
              }),
            )
            .setStrokeStyle(strokeStyle);
          const onMouseOver = (e, type) => {
            setHoverElement({
              position: { x: e.pageX, y: e.pageY },
              size: { start: '1', end: '2' },
              isEventDrawing: true,
              customText: type,
              onRight: false,
            });
          };
          const i2 = i;
          const c2 = current;
          pols.onMouseMove((t, e) => {
            onMouseOver(e, c2);
          });
          pols.onMouseLeave((t, e) => {
            setHoverElement(null);
          });

          allEventsChart
            .addUIElement(UIElementBuilders.TextBox, splineSeriesLast.scale)
            .setText(current)
            .setDraggingMode(lcjs.UIDraggingModes.onlyHorizontal)
            .setPosition({
              x: (globalData.wakeData[currentX].startTimeMicro + globalData.wakeData[i].startTimeMicro) / 2,
              y: y,
              z: 1,
            })
            .setMouseInteractions(false)
            .setTextFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 250) }))
            .setTextFont(
              new FontSettings({
                size: 12,
              }),
            )
            .setBackground((background) => background.setFillStyle(emptyFill).setStrokeStyle(emptyLine));
          current = c;
          currentX = i;
        }
      }
    }

    //
    // .setGridStrokeLength(0)

    // .setTextFormatter((_) => yCooridantes[y])
    // .setMarker(
    //   (tickMarker) => tickMarker
    //   // Style TickMarker.
    //   //.setTextFillStyle(new SolidFill({ color: ColorRGBA(255, 0, 0) }))
    // );
    // .setMarker((marker) => marker.setTextFillStyle(new SolidFill({ color: ColorRGBA(170, 170, 170) })));

    // splineSeriesLast.add(signalsData[0].data);
    // splineSeriesLast.setStrokeStyle(`
    //   new SolidLine({
    //     thickness: 1,
    //   })
    // );
    return allEventsChartAxis;
  }, [events, visibleEvents]);

  const windowResize = useCallback(() => {
    redrawCharts = true;
    handleResize();
    if (!document.getElementById('event_selector_button')) return;
    const coords = document.getElementById('event_selector_button').getBoundingClientRect();
    const listElement = document.getElementById('event_selector_list');
    listElement.style.top = coords.bottom + 'px';
    listElement.style.left = coords.left - 80 + 'px';
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener('resize', windowResize);
    return () => window.removeEventListener('resize', windowResize);
  }, [windowResize]);

  useEffect(() => {
    //if (!interval.start && !getStudyIntervalRunning) getStudyInterval(studySignals);
  }, [getStudyInterval, interval.start]);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    const mouseUpEvent = window.addEventListener('mouseup', function (e) {
      if (draggingOutsideTimer || currentDrawingSector || globalData.resizerPressed || globalData.eventPressed) draggingOutsideEnd(e);
    });
    const width = document.body.getBoundingClientRect().width;
    const mouseMoveEvent = window.addEventListener('mousemove', function (e) {
      if (globalData.eventPressed) {
        const mouseOutsideRight =
          e.clientX > width - 30 - globalData.eventPressed.rightBorderDist && e.clientX > globalData.eventPressed.clientX;
        const mouseOutsideLeft = e.clientX + globalData.eventPressed.leftBorderDist < 130 && e.clientX < globalData.eventPressed.clientX;
        if (mouseOutsideRight || mouseOutsideLeft) {
          if (draggingOutsideTimer) return;
          const chart = chartsList[globalData.eventPressed.signalId];
          const curLocationAxis = translatePoint(
            chart.engine.clientLocation2Engine(e.clientX, e.clientY),
            chart.engine.scale,
            signalChartsList[globalData.eventPressed.signalId].scale,
          );
          const t = Math.floor((zoomIntervalGlobal.end - zoomIntervalGlobal.start) / 80) * (mouseOutsideRight ? 1 : -1);
          rightDraggingoutside = mouseOutsideRight;
          globalData.eventPressed.newPoints = globalData.eventPressed.points.slice(0);
          globalData.eventPressed.newPoints = globalData.eventPressed.newPoints.map((p) => ({
            x:
              p.x +
              (mouseOutsideRight
                ? curLocationAxis.x - globalData.eventPressed.position.x
                : curLocationAxis.x - globalData.eventPressed.position.x),
            y: p.y,
          }));

          draggingOutsideTimer = setInterval(() => {
            if (!globalData.eventPressed) return;
            const start = zoomIntervalGlobal.start + t;
            const end = zoomIntervalGlobal.end + t;
            if (rightDraggingoutside && end >= globalData.dateEndTimeDiff) return;
            if (!rightDraggingoutside && start < 0) {
              draggingOutsideEnd(e);
              return;
            }
            zoomIntervalGlobal = { start, end };
            globalData.firstChart.setInterval(start, end);
            zoomUpdate(start, end);

            if (globalData.eventPressed.newPoints) {
              globalData.eventPressed.newPoints = globalData.eventPressed.newPoints.map((p) => ({
                x: p.x + t,
                y: p.y,
              }));
              globalData.eventPressed.figure.setDimensions(globalData.eventPressed.newPoints);
            }
          }, 100);
        } else {
          if (draggingOutsideTimer) {
            draggingOutsideEnd(e);
          }
        }
      }
      if (currentDrawingSector && currentDrawingSignalId && startPoint) {
        const chart = chartsList[currentDrawingSignalId];
        const curLocationAxis = translatePoint(
          chart.engine.clientLocation2Engine(e.clientX, e.clientY),
          chart.engine.scale,
          signalChartsList[currentDrawingSignalId].scale,
        );
        const mouseOutsideRight = e?.clientX > width - 90;
        const mouseOutsideLeft = e.clientX < 200;

        if (draggingOutsideTimer) {
          if (!mouseOutsideLeft && !mouseOutsideRight) {
            clearInterval(draggingOutsideTimer);
            draggingOutsideTimer = null;
            globalData.drawingPolygonEventFunction(curLocationAxis, currentDrawingSector, currentDrawingSignalId);
          }
          return;
        }

        if (mouseOutsideRight) {
          draggingOutsideStart(currentDrawingSignalId, curLocationAxis.y, true);

          return;
        }
        if (mouseOutsideLeft) {
          draggingOutsideStart(currentDrawingSignalId, curLocationAxis.y, false);
          return;
        }
        globalData.drawingPolygonEventFunction(curLocationAxis, currentDrawingSector, currentDrawingSignalId);
      }

      if (globalData.resizeDragOutside && globalData.currentDrawingSignalId) {
        const mouseOutsideRight = e.clientX > width - 90;
        const mouseOutsideLeft = e.clientX < 200;
        if (mouseOutsideLeft || mouseOutsideRight) {
          if (draggingOutsideTimer) return;
          const t = Math.floor((zoomIntervalGlobal.end - zoomIntervalGlobal.start) / 80);
          rightDraggingoutside = mouseOutsideRight;
          draggingOutsideTimer = setInterval(() => {
            if (!globalData.resizeDragOutside) return;
            let x = rightDraggingoutside ? zoomIntervalGlobal.end + t : zoomIntervalGlobal.start - t;
            if (rightDraggingoutside && x >= globalData.dateEndTimeDiff) x = globalData.dateEndTimeDiff;
            if (!rightDraggingoutside && x < 0) x = 0;
            const start = rightDraggingoutside ? zoomIntervalGlobal.start : x;
            const end = rightDraggingoutside ? x : zoomIntervalGlobal.end;
            globalData.firstChart.setInterval(start, end);
            zoomUpdate(start, end);
            globalData.resizeDragOutside(rightDraggingoutside ? zoomIntervalGlobal.end : zoomIntervalGlobal.start);
          }, 100);
        } else {
          if (draggingOutsideTimer) {
            clearInterval(draggingOutsideTimer);
            draggingOutsideTimer = null;
          }
          const chart = chartsList[globalData.currentDrawingSignalId];
          const newPosition = translatePoint(
            chart.engine.clientLocation2Engine(e.clientX, e.clientY),
            chart.engine.scale,
            signalChartsList[globalData.currentDrawingSignalId].scale,
          );

          globalData.resizeDragOutside(newPosition.x);
        }
      }
      //if (draggingOutsideTimer) draggingOutsideEnd();
    });
    return () => {
      window.removeEventListener('mouseup', mouseUpEvent);
      window.removeEventListener('mousemove', mouseMoveEvent);
    };
  }, [draggingOutsideEnd]);

  useEffect(() => {
    if (!firstChart) return;
    firstChart.onScaleChange(debounce(zoomUpdate));
    globalData.firstChart = firstChart;
  }, [firstChart, zoomUpdate]);

  const nextPage = useCallback(
    (next = true, distance1 = null) => {
      let distance = distance1 || zoomIntervalGlobal.end - zoomIntervalGlobal.start;
      if (shiftPressed && !distance1) distance = distance / 3;
      let int = {};
      if (next) {
        const max = interval.end.getTime() - interval.start.getTime();
        if (zoomInterval.end + distance > max) {
          int = { start: max - distance, end: max };
        } else
          int = {
            start: zoomIntervalGlobal.start + distance,
            end: zoomIntervalGlobal.end + distance,
          };
      } else {
        if (zoomIntervalGlobal.start - distance < 0) {
          int = {
            start: 0,
            end: zoomIntervalGlobal.end - zoomIntervalGlobal.start,
          };
        } else {
          int = {
            start: zoomIntervalGlobal.start - distance,
            end: zoomIntervalGlobal.end - distance,
          };
        }
      }
      setZoomInterval(int);
      firstChart.setInterval(int.start, int.end);
    },
    [firstChart, zoomInterval, interval],
  );

  useEffect(() => {
    if (moveInterval.current) clearInterval(moveInterval.current);
    if (!speed) return;
    const time = playMode === 'Page' ? 3000 / Math.abs(speedMultiplier) : 30;
    moveInterval.current = setInterval(() => {
      if (!speed) return;
      if (playMode === 'Page') {
        nextPage(speed > 0);
      } else {
        const int = firstChart.getInterval();
        firstChart.setInterval(int.start + speed, int.end + speed);
        if (Date.now() - lastZooomUpdate > 300) {
          lastZooomUpdate = Date.now();
          zoomUpdate(int.start + speed, int.end + speed);
        }
      }
    }, time);
  }, [firstChart, speed, nextPage, playMode, speedMultiplier]);

  const setPlay = (change = 0) => {
    if (speed && !change) {
      //setSpeedMultiplier(1);
      setSpeed(0);
      document.querySelector('.bodyContainer').classList.toggle('right', false);
    } else {
      let i = null;
      if (speedMultiplier < -1.25 && change > 0) i = 6;
      if (speedMultiplier > 1.25 && change < 0) i = 5;
      if (speedMultiplier === 1) i = 6;
      if (i === null) i = speedMultipliers.findIndex((m) => m === speedMultiplier) + change;
      if (i === speedMultipliers.length) i = speedMultipliers.length / 2;
      if (i < 0) i = speedMultipliers.length / 2 - 1;

      const t = speedMultipliers[i];
      if (change !== 0) setSpeedMultiplier(t);
      globalData.speedMultiplier = t;

      const int = firstChart.getInterval();
      setSpeed((t * (int.end - int.start)) / 200);
      document.querySelector('.bodyContainer').classList.toggle('right', true);
    }
  };

  const nextHour = useCallback(
    (next = true, n = 1) => {
      const distance = zoomInterval.end - zoomInterval.start;
      let newDate = new Date(interval.start.getTime() + zoomInterval.start + 3600000 * (next ? n : -n));
      newDate.setMinutes(0);
      newDate.setSeconds(0);
      let newTime = newDate.getTime() - interval.start.getTime();
      if (newTime < 0) newTime = 0;
      if (newDate.getTime() + distance > interval.end.getTime()) newTime = interval.end.getTime() - interval.start.getTime() - distance;
      setZoomInterval({ start: newTime, end: newTime + distance });
      firstChart.setInterval(newTime, newTime + distance);
    },
    [firstChart, interval, zoomInterval],
  );

  const onChange = (value) => {
    if (newEventSize?.customFields) newEventSize.customFields = undefined;
    setSelectedEvent({ ...selectedEvent, newText: value });
  };

  const changeEventType = useCallback(
    (eventId, signalId, oldType, newType) => {
      //if (lastPolygonFigure) lastPolygonFigure.restore();
      if (newType === oldType) return;
      newType = newType.replace(' Apnea', 'Apnea');

      let index = null;
      if (!globalData.allEventsList[newType]) globalData.allEventsList[newType] = [];
      index = globalData.allEventsList[oldType].findIndex((e) => e.id === eventId);
      if (index < 0) return;
      globalData.allEventsList[oldType][index].figure?.dispose();
      globalData.allEventsList[oldType][index].textFigure?.dispose();
      globalData.allEventsList[oldType][index].resizeElementFront?.dispose();
      globalData.allEventsList[oldType][index].resizeElementBack.dispose();

      globalData.allEventsList[oldType].splice(index, 1);

      const newEvents = { ...globalData.loadedEventsData };
      newEvents[signalId] = globalData.loadedEventsData[signalId].slice(0);
      const eventIndex = newEvents[signalId].findIndex((e) => e.id === eventId);
      if (eventIndex >= 0) {
        const newEvent = {
          ...newEvents[signalId][eventIndex],

          Type: newType,
          type: newType,
          updated: true,
        };

        newEvent.yPos = globalData.eventTopCustom[signalId] && globalData.eventTopCustom[signalId][newType];

        newEvents[signalId][eventIndex] = newEvent;
        (async () => {
          const e = await saveEvent(newEvent, signalId);
          if (e) {
            // addHistoryEvent({
            //   signalId: signalId,
            //   prev: events[signalId],
            //   next: newEvents[signalId],
            //   type: 'event',
            //   title: 'update event',
            // });
            newEvents[signalId][eventIndex].SleepEventId = e.SleepEventId;
            globalData.loadedEventsData[signalId] = newEvents[signalId];

            globalData.drawEventFunction[signalId](newEvent, signalId);

            // globalData.eventsUpdated = true;
            //redrawCharts = true;
            setEvents(newEvents);

            setTimeout(() => {
              clearAfterSave();
            }, 1000);
          } else {
            alert('Error occured when saving events');
          }
        })();
      }
    },
    [events],
  );

  const handleKeyUp = useCallback((e) => {
    if (shiftPressed && e.key === 'Shift') {
      shiftPressed = false;
      return;
    }
  }, []);

  const updateZoomInterval = useCallback(
    (start, end) => {
      setZoomInterval({ start, end });
      firstChart?.setInterval(start, end);
    },
    [firstChart, setZoomInterval],
  );

  const handleKey = useCallback(
    (e) => {
      if (!shiftPressed && e.key === 'Shift') {
        shiftPressed = true;
        return;
      }
      let event = hoverElement?.event;
      if (e.keyCode === 46 && hoverElement?.isArtifact) {
        if (hoverElement.sliderId) {
          globalData.artifacts
            .filter((t) => t.sliderId === hoverElement.sliderId)
            .forEach((artifact) => {
              if (artifact.signalId && globalData.chartsList[artifact.signalId]) {
                deleteArtifact(true, artifact.id);
              }
            });
        } else deleteArtifact(true, hoverElement.id);
        return;
      }
      if (!event && selectedEvent && eventEditMode) {
        event = {
          type: selectedEvent.text,
          id: selectedEvent.id,
          SignalId: selectedEvent.signalId,
        };
      }
      if (globalData.selectedSleepElement) {
        const names = {
          0: 'W',
          1: 'N1',
          2: 'N2',
          3: 'N3',
          5: 'REM',
        };
        switch (e.key) {
          case '0':
          case '1':
          case '2':
          case '3':
          case '5':
          case 'ArrowRight':
            (async () => {
              if (!globalData.wakeSignalData || !globalData.selectedSleepElement) return;
              if (names[e.key]) await updateWaterMarkText(names[e.key]);
              if (globalData.selectedSleepElement.last) {
                globalData.selectedSleepElement.i++;
              }

              globalData.selectedSleepElement.j++;
              globalData.selectedSleepElement.x += 30000;
              globalData.selectedSleepElement.moving = true;
              globalData.selectedSleepElement.lastOnScreen = globalData.selectedSleepElement.x >= zoomIntervalGlobal.end;
              if (globalData.selectedSleepElement.lastOnScreen)
                updateZoomInterval(zoomIntervalGlobal.start + 30000, zoomIntervalGlobal.end + 30000);

              loadWakeSignal(globalData.wakeSignalData, () => {
                drawLSLabels();
                drawAllEventsChart();
              });
            })();

            break;
          case 'ArrowLeft':
            if (globalData.selectedSleepElement.j > 0) {
              if (!globalData.wakeSignalData) return;
              globalData.selectedSleepElement.j--;
              if (globalData.selectedSleepElement.first) globalData.selectedSleepElement.i--;
              //globalDat.waterMarkClick()
              if (zoomIntervalGlobal.start >= 30000) {
                globalData.selectedSleepElement.moving = true;
                updateZoomInterval(zoomIntervalGlobal.start - 30000, zoomIntervalGlobal.end - 30000);
                loadWakeSignal(globalData.wakeSignalData, () => {
                  drawLSLabels();
                });
              } else drawLSLabels();
            }
            break;
          default:
            break;
        }
        return;
      }
      // setSelectedEvent({
      //   text: type,
      //   newText: type,
      //   id,
      //   signalId,
      //   added: event.added,
      //   customCharacteristics: event.customCharacteristics,
      //   lastTextElement: t,
      //   lastPolygonFigure: t2,
      //   y,
      //   h,
      // });
      if (event?.type) {
        const signal = globalData.loadedChartsData[event?.SignalId];
        if (eventEditMode) {
          const e = document.getElementById('eventContextMenu');
          e?.classList.remove('visible');
          setEventEditMode(false);
        }
        if (e.keyCode === 46) {
          deleteEvent({
            id: event.id,
            signalId: event.SignalId,
            text: event.type,
          });
          return;
        }
        if (signal?.Type === 'Pulse') {
          switch (e.keyCode) {
            case 66:
              changeEventType(event.id, event.SignalId, event.type, 'Bradycardia');
              return;
            case 84:
              changeEventType(event.id, event.SignalId, event.type, 'Tachycardia');
              return;
          }
        }
        if (signal?.Type === 'Airflow') {
          switch (e.keyCode) {
            case 72:
              changeEventType(event.id, event.SignalId, event.type, 'Hypopnea');
              return;
            case 79:
              changeEventType(event.id, event.SignalId, event.type, 'Obstructive Apnea');
              return;
            case 67:
              changeEventType(event.id, event.SignalId, event.type, 'Central Apnea');
              return;
            case 77:
              changeEventType(event.id, event.SignalId, event.type, 'Mixed Apnea');
              return;
            case 82:
              changeEventType(event.id, event.SignalId, event.type, 'RERA');
              return;
          }
        }
      }
      switch (e.key) {
        case 'Home':
          nextHour(false, 1000000);
          break;
        case 'End':
          nextHour(true, 1000000);
          break;
        case 'PageUp':
          nextPage(false);
          break;
        case 'PageDown':
          nextPage();
          break;
        case 'ArrowRight':
          nextPage();
          break;
        case 'ArrowLeft':
          nextPage(false);
          break;
        default:
          break;
      }
    },
    [nextPage, nextHour, hoverElement, updateZoomInterval, drawLSLabels, eventEditMode, selectedEvent, changeEventType],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKey);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKey);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKey, handleKeyUp]);

  const addEvent = useCallback(
    async (cancel = false) => {
      globalData.eventPressed = null;
      if (cancel) {
        if (!eventEditMode) {
          globalData.lastTextElement?.dispose();
          lastPolygonFigure?.dispose();
          lastDrawingPolygonFigure?.dispose();
          setSelectedEvent(null);
          currentDrawingSignalId = null;
        }
      } else {
        if (selectedEvent) {
          //if (lastPolygonFigure) lastPolygonFigure.restore();
          let id = selectedEvent.id;
          const newText = selectedEvent.newText || selectedEvent.text;
          const text = eventEditMode ? selectedEvent.text : newText;
          let start = newEventSize.start ? newEventSize.start - interval.start.getTime() : eventSize.start;
          let end = newEventSize.end ? newEventSize.end - interval.start.getTime() : eventSize.end;
          const sigId = modalOpen || selectedEvent.signalId;

          let index = null;
          if (eventEditMode || selectedEvent?.edit) {
            if (!globalData.allEventsList[text] || !id) return;
            index = globalData.allEventsList[text].findIndex((e) => e.id === id);
            if (index < 0) return;

            const crossedEvent = checkOverlap(newText, id, sigId, start, end, globalData.allEventsList);
            if (crossedEvent) {
              alert(crossedEvent);
              return;
            }

            if (text !== 'CandidateEvent' && checkArtifactOverlaping(start, end, sigId, null)) {
              alert('Event cannot overlap an artifact');
              return;
            }

            globalData.allEventsList[text][index] = {
              ...globalData.allEventsList[text][index],
              start,
              end,
              Type: newText,
            };
            const newEvents = { ...events };
            newEvents[sigId] = events[sigId].slice(0);
            const eventIndex = newEvents[sigId].findIndex((e) => e.id === id);
            if (eventIndex >= 0) {
              const newEvent = {
                ...events[sigId][eventIndex],
                StartTime: moment(start + dateOriginTime)
                  .utcOffset(0, true)
                  .format()
                  .replace('Z', ''),
                EndTime: moment(end + dateOriginTime)
                  .utcOffset(0, true)
                  .format()
                  .replace('Z', ''),
                Type: newText,
                type: newText,
                id: id,
                updated: true,
                startTimeMicro: start,
                endTimeMicro: end,
              };
              if (newEventSize?.customFields)
                newEvent.CustomCharacteristics = {
                  ...newEvent.CustomCharacteristics,
                  ...newEventSize?.customFields,
                };
              newEvents[sigId][eventIndex] = newEvent;
              (async () => {
                const e = await saveEvent(newEvent, sigId);
                if (e) {
                  // addHistoryEvent({
                  //   signalId: sigId,
                  //   prev: events[sigId],
                  //   next: newEvents[sigId],
                  //   type: 'event',
                  //   title: 'update event',
                  // });
                  newEvents[sigId][eventIndex].SleepEventId = e.SleepEventId;
                  globalData.loadedEventsData[sigId] = newEvents[sigId];
                  globalData.eventsUpdated = true;
                  redrawCharts = true;
                  setEvents(newEvents);
                  currentDrawingSignalId = null;
                } else {
                  alert('Error occured when saving events');
                }
              })();
            }
            //globalData.lastTextElement.setText(newT);
            // lastPolygonFigure?.dispose();
            // globalData.lastTextElement?.dispose();
          } else {
            const newEvents = { ...globalData.loadedEventsData };
            newEvents[sigId] = globalData.loadedEventsData[sigId].slice(0);
            const newEvent = {
              StartTime: moment(start + dateOriginTime)
                .utcOffset(0, true)
                .format()
                .replace('Z', ''),
              EndTime: moment(end + dateOriginTime)
                .utcOffset(0, true)
                .format()
                .replace('Z', ''),
              Type: text,
              type: text,
              id: eventId,
              SignalId: sigId,
              Status: 'Altered',
              added: true,
              startTimeMicro: start,
              endTimeMicro: end,
            };
            if (text !== 'CandidateEvent' && checkArtifactOverlaping(start, end, sigId, null)) {
              alert('Event cannot overlap an artifact');
              return;
            }
            if (['Fall', 'Small Rise'].includes(text)) {
              newEvent.type = text === 'Fall' ? 'Desaturation' : 'Recovery';
              newEvent.Type = text === 'Fall' ? 'Desaturation' : 'Recovery';
              newEvent.CustomCharacteristics = {
                'DPAR Event Type': text,
              };
              newEvent.customName = text;
            }
            if (newEventSize?.customFields)
              newEvent.CustomCharacteristics = {
                ...newEvent.CustomCharacteristics,
                ...newEventSize?.customFields,
              };
            newEvents[sigId].push(newEvent);
            if (!globalData.allEventsList[newEvent.Type]) globalData.allEventsList[newEvent.Type] = [];
            else {
              start = newEventSize?.start ? newEventSize.start - interval.start.getTime() : tempEvent.start;
              end = newEventSize?.end ? newEventSize.end - interval.start.getTime() : tempEvent.end;
              const crossedEvent = checkOverlap(newEvent.Type, id, sigId, start, end, globalData.allEventsList, text);
              if (crossedEvent) {
                alert(crossedEvent);
                globalData.lastTextElement?.dispose();
                lastPolygonFigure?.dispose();
                return;
              }
            }
            (async () => {
              const e = await saveEvent(newEvent, sigId);
              if (e) {
                // addHistoryEvent({
                //   signalId: sigId,
                //   prev: events[sigId],
                //   next: newEvents[sigId],
                //   type: 'event',
                //   title: 'add event',
                // });

                newEvents[sigId][newEvents[sigId].length - 1].SleepEventId = e.SleepEventId;
                globalData.loadedEventsData[sigId] = newEvents[sigId];
                globalData.eventsUpdated = true;
                setEvents(newEvents);

                //index = globalData.allEventsList[text].length - 1;
                id = eventId;

                globalData.lastTextElement?.dispose();
                lastPolygonFigure?.dispose();
                lastDrawingPolygonFigure?.dispose();

                globalData.drawEventFunction[sigId](newEvent, sigId);
                lastPolygonFigure = null;
                currentDrawingSignalId = null;
              } else {
                alert('Error occured when saving events');
                lastPolygonFigure?.dispose();
                lastDrawingPolygonFigure?.dispose();
                currentDrawingSignalId = null;
              }
            })();
          }
          eventId++;

          // if (index !== null) {
          //   globalData.allEventsList[text][index]["figure"] = lastPolygonFigure;
          // }
          // redrawSignal(sigId);
        }
      }
      //if (window.eventDrawing) addEventActivate();

      newEventSize = {};
      setModalOpen(false);
      setSelectedEvent(null);
    },
    [addHistoryEvent, eventEditMode, eventSize, events, interval, modalOpen, selectedEvent, redrawSignal],
  );

  const deleteEvent = useCallback(
    (selectedEvent) => {
      if (!selectedEvent?.signalId) return;
      const signalId = selectedEvent.signalId;
      const id = selectedEvent.id;
      const type = selectedEvent.text;
      const newEvents = globalData.loadedEventsData[signalId].slice(0);
      const i = newEvents.findIndex((e) => e.id === id);

      newEvents[i] = { ...newEvents[i], removed: true };
      const deletedEvents = [newEvents[i]];
      let newEvents1 = {
        ...globalData.loadedEventsData,
        [signalId]: newEvents,
      };
      if (signalId === globalData.pulseSignalId && type === 'RERA' && globalData.allEventsList['RDI_RERA']) {
        const start = newEvents[i].startTimeMicro - 50000;
        const end = newEvents[i].endTimeMicro;
        const connectedEvents = globalData.allEventsList['RDI_RERA'].filter((ev) => {
          return (ev.start >= start && ev.start <= end) || (ev.end >= start && ev.end <= end);
        });
        if (connectedEvents.length) {
          const sigId = connectedEvents[0].signalId;
          const newEvents2 = globalData.loadedEventsData[sigId].slice(0);
          connectedEvents.forEach((ev) => {
            const j = newEvents2.findIndex((e) => e.id === ev.id);
            newEvents2[j] = { ...newEvents2[j], removed: true };
            deletedEvents.push(newEvents2[j]);
            const fo = globalData.allEventsList['RDI_RERA'].filter((e) => e.id === ev.id);
            if (fo.length) {
              fo[0].figure?.dispose();
              fo[0].textFigure?.dispose();
              fo[0].resizeElementBack?.dispose();
              fo[0].resizeElementFront?.dispose();
            }
            globalData.allEventsList['RDI_RERA'] = globalData.allEventsList['RDI_RERA'].filter((e) => e.id !== ev.id);
          });
          globalData.loadedEventsData[sigId] = newEvents2;
          newEvents1[sigId] = newEvents2;
        }
      }
      if (signalId === globalData.spoSignalId && globalData.airflowSignalId && type === 'Desaturation') {
        const start = newEvents[i].startTimeMicro - 50000;
        const end = newEvents[i].endTimeMicro;
        const newEvents2 = globalData.loadedEventsData[globalData.airflowSignalId].slice(0);
        newEvents2.forEach((ev, j) => {
          if ((ev.startTimeMicro >= start && ev.startTimeMicro <= end) || (ev.endTimeMicro >= start && ev.endTimeMicro <= end)) {
            newEvents2[j] = { ...newEvents2[j], removed: true };
            deletedEvents.push(newEvents2[j]);
            const fo = globalData.allEventsList[ev.type].filter((e) => e.id === ev.id);
            if (fo.length) {
              fo[0].figure?.dispose();
              fo[0].textFigure?.dispose();
              fo[0].resizeElementBack?.dispose();
              fo[0].resizeElementFront?.dispose();
            }
            globalData.allEventsList[ev.type] = globalData.allEventsList[ev.type].filter((e) => e.id !== ev.id);
          }
        });
        globalData.loadedEventsData[globalData.airflowSignalId] = newEvents2;
        newEvents1[globalData.airflowSignalId] = newEvents2;
      }
      globalData.loadedEventsData[signalId] = newEvents;
      globalData.eventsUpdated = true;

      setEvents(newEvents1);
      const fo = globalData.allEventsList[type].filter((e) => e.id === id);
      if (fo.length) {
        fo[0].figure?.dispose();
        fo[0].textFigure?.dispose();
        fo[0].resizeElementBack?.dispose();
        fo[0].resizeElementFront?.dispose();
      }
      globalData.allEventsList[type] = globalData.allEventsList[type].filter((e) => e.id !== id);

      newEventSize = {};
      setModalOpen(false);
      setSelectedEvent(null);
      globalData.eventPressed = null;
      saveEvents(deletedEvents);
      drawAllEventsChart();
    },
    [selectedEvent, drawAllEventsChart],
  );

  const switchCharts = useCallback(
    (oldPosition, newPosition, noHistory = false) => {
      if (oldPosition === newPosition) return;
      const el = selectedCharts[oldPosition];
      let charts = [];
      const otherElements = selectedCharts.filter((t, i) => i !== oldPosition);
      if (newPosition < 0) {
        charts = [el].concat(otherElements);
      } else {
        if (newPosition >= selectedCharts.length) {
          charts = otherElements.concat([el]);
        } else {
          otherElements.splice(newPosition, 0, el);
          charts = otherElements;
        }
      }
      // if (!noHistory)
      //   addHistoryEvent({
      //     type: 'chartOrder',
      //     prev: selectedCharts,
      //     next: charts,
      //     title: 'charts order change',
      //   });
      updateChartsOrder(charts);
      return charts;
    },
    [selectedCharts, updateChartsOrder],
  );

  const moveSourceChart = useCallback(
    (oldPosition, newPosition, signalId, noHistory = false) => {
      const charts = selectedCharts.slice(0);
      const mainSignalPosition = charts[oldPosition];
      const i = signalIds.findIndex((s) => s.SignalId === globalData.additionalLines[signalId]);
      charts[oldPosition] = i;

      charts.splice(newPosition, 0, mainSignalPosition);
      globalData.additionalLines[signalId] = null;
      // if (!noHistory)
      //   addHistoryEvent({
      //     type: 'chartOrder',
      //     prev: selectedCharts,
      //     next: charts,
      //     title: 'charts order change',
      //   });
      updateChartsOrder(charts);
      return charts;
    },
    [selectedCharts, updateChartsOrder, signalIds],
  );

  const moveSecondChart = useCallback(
    (signalId, sourceId, newPosition) => {
      const charts = selectedCharts.slice(0);
      const i = signalIds.findIndex((s) => s.SignalId === signalId);
      charts.splice(newPosition, 0, i);
      globalData.additionalLines[sourceId] = null;
      // addHistoryEvent({
      //   type: 'chartOrder',
      //   prev: selectedCharts,
      //   next: charts,
      //   title: 'charts order change',
      // });
      updateChartsOrder(charts);
    },
    [selectedCharts, updateChartsOrder, signalIds],
  );

  const addSecondCharts = useCallback(
    (s1, s2, unused = true) => {
      globalData.additionalLines[s1] = s2;
      let charts = selectedCharts.slice(0);
      if (!unused) {
        const i = signalIds.findIndex((s) => s.SignalId === s2);
        charts = selectedCharts.filter((c) => c !== i).slice(0);
      }
      // addHistoryEvent({
      //   type: 'chartOrder',
      //   prev: selectedCharts,
      //   next: charts,
      //   title: 'charts order change',
      // });
      updateChartsOrder(charts);
    },
    [selectedCharts, signalIds, updateChartsOrder],
  );

  const addCharts = useCallback(
    (signalId, newIndex, noHistory = false) => {
      const el = signalIds.findIndex((s) => s.SignalId === signalId);
      let charts = [];
      const otherElements = selectedCharts;
      if (newIndex < 0) {
        charts = [el].concat(otherElements);
      } else {
        if (newIndex >= selectedCharts.length) {
          charts = otherElements.concat([el]);
        } else {
          otherElements.splice(newIndex, 0, el);
          charts = otherElements;
        }
      }
      // if (!noHistory)
      //   addHistoryEvent({
      //     type: 'chartOrder',
      //     prev: selectedCharts,
      //     next: charts,
      //     title: 'charts order change',
      //   });

      updateChartsOrder(charts);
      return charts;
    },
    [selectedCharts, updateChartsOrder, signalIds],
  );

  const addFromDouble = useCallback(
    (signalId, signalIdSource, newIndex, noHistory = false) => {
      globalData.additionalLines[signalIdSource] = null;
      addCharts(signalId, newIndex, noHistory);
    },
    [addCharts],
  );

  const findEvent = useCallback(
    (moveType) => {
      if (searchEvent && globalData.eventsTyped[searchEvent]) {
        let i = selectedSearhEvent ? selectedSearhEvent.index : -1;
        const events = globalData.eventsTyped[searchEvent];

        switch (moveType) {
          case 'next':
            if (i < events.length - 1) i++;
            break;
          case 'last':
            i = events.length - 1;
            break;
          case 'prev':
            i = i > 0 ? i - 1 : 0;
            break;
          case 'first':
            i = 0;
            break;
          default:
            break;
        }

        let distance = zoomInterval.end - zoomInterval.start;
        const start = events[i].startTimeMicro > distance / 2 ? events[i].startTimeMicro - distance / 2 : 0;
        if (start + distance < events[i].end) distance = events[i].endTimeMicro - start + 100;
        searchedEventId = events[i].id;
        //globalData.allEventsList;
        setZoomInterval({ start: start, end: start + distance });
        firstChart.setInterval(start, start + distance);
        setSelectedSearchEvent({ index: i });
      }
    },
    [selectedSearhEvent, setSelectedSearchEvent, firstChart, zoomInterval, searchEvent],
  );

  const clearAfterSave = useCallback(
    (clearArtifacts = false) => {
      if (clearArtifacts) {
        let arts = artifacts.slice(0);
        arts.forEach((artifact, i) => {
          if (artifact.added || artifact.updated || artifact.removed) {
            arts[i].added = undefined;
            arts[i].updated = undefined;
            arts[i].removed = undefined;
          }
        });
        setArtifactsState(arts);
      } else {
        Object.keys(events).forEach((signalId) => {
          events[signalId].forEach((event, i) => {
            if (event.added || event.updated || event.removed) {
              events[signalId][i].added = undefined;
              events[signalId][i].updated = undefined;
              events[signalId][i].removed = undefined;
            }
          });
        });
        setEvents({ ...events });
      }
    },
    [events, artifacts],
  );

  const playItems = useMemo(() => {
    return [
      {
        key: 'page',
        label: (
          <Radio.Group
            onChange={(e) => {
              setPlayMode(e.target.value);
              globalData.viewMode = e.target.value;
            }}
            value={playMode}
            disabled={true}
          >
            <Space direction="vertical">
              <Radio value={'Page'}>Page mode</Radio>
              <Radio checked={playMode === 'Scroll'} value={'Scroll'}>
                Scroll mode
              </Radio>
            </Space>
          </Radio.Group>
        ),
      },
    ];
  }, [playMode, setPlayMode]);

  useEffect(() => {
    drawAllEventsChart();
  }, [drawAllEventsChart]);

  const eventVisibleSelectorClick = useCallback(
    (checked, event) => {
      let e2 = event;
      if (event === 'Cycling' || event === 'Reciprocation') e2 = 'Oximetry' + event;
      if (checked) {
        // addHistoryEvent({
        //   type: "eventTypeVisibility",
        //   prev: visibleEvents,
        //   next: visibleEvents.concat([event]),
        //   title: "event type visibility",
        // });
        if (globalData.allEventsList[e2]) {
          globalData.allEventsList[e2].forEach((e) => {
            e.figure.restore();
            e.textFigure.restore();
            e.resizeElementFront?.restore();
            e.resizeElementBack?.restore();
          });
        }
        if (!visibleEvents.includes(event)) setVisibleEvents(visibleEvents.concat([event]));
      } else {
        const t = visibleEvents.filter((e) => e !== event);
        // addHistoryEvent({
        //   type: "eventTypeVisibility",
        //   prev: visibleEvents,
        //   next: t,
        //   title: "event type visibility",
        // });
        if (visibleEvents.includes(event)) setVisibleEvents(t);
        if (globalData.allEventsList[e2]) {
          globalData.allEventsList[e2].forEach((e) => {
            e.figure?.dispose();
            e.textFigure?.dispose();
            e.resizeElementFront?.restore();
            e.resizeElementBack?.restore();
          });
        }
      }
    },
    [visibleEvents],
  );

  const eventVisibleItems = useMemo(() => {
    return allEventTypesList.map((e, i) => ({
      key: i,
      label: (
        <span className="event_selector_item">
          <Checkbox onChange={(e1) => eventVisibleSelectorClick(e1.target.checked, e)} checked={visibleEvents.includes(e)}>
            {e}
          </Checkbox>
        </span>
      ),
    }));
  }, [visibleEvents, eventVisibleSelectorClick]);

  const updateInterval = useCallback(
    (value) => {
      if (value) {
        const start = Math.floor(zoomInterval.start);
        firstChart.setInterval(start, start + value * 1000);
        setZoomInterval({
          start: start,
          end: zoomInterval.start + value * 1000,
        });
      }
    },
    [firstChart, zoomInterval],
  );

  const timeIntervalItems = useMemo(() => {
    return [
      {
        key: 'page',
        label: (
          <Radio.Group
            onChange={(e) => {
              setSelectedTimeTinterval(timeIntervalSizes.find((t) => t.value === e.target.value));
              updateInterval(e.target.value);
            }}
            value={selectedTimeInterval.value}
          >
            <Space direction="vertical">
              {timeIntervalSizes.map((int, i) => (
                <Radio key={'timeIntervalSizes' + i} value={int.value}>
                  {int.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        ),
      },
    ];
  }, [selectedTimeInterval, setSelectedTimeTinterval, updateInterval]);

  const eventVisibleSelectorClickHandle = useMemo((e) => {
    setVisibleEventTypesSelectorOpen(false);
  }, []);

  // const saveData = useCallback(
  //   (studyData = null) => {
  //     saveEvents(events, artifacts, studyId, patientId, studyData);
  //   },
  //   [studyId, patientId],
  // );

  useEffect(() => {
    if (globalData.eventsUpdated) {
      // saveData(studyData);
      globalData.eventsUpdated = false;
    }
  }, [studyData]);

  const eventContextElement = useMemo(() => {
    return eventContextMenu(
      selectedEvent?.signalId || 0,
      selectedEvent?.text,
      (type) => {
        changeEventType(selectedEvent.id, selectedEvent.signalId, selectedEvent.text, type);
        const e = document.getElementById('eventContextMenu');
        e.classList.remove('visible');
      },
      () => {
        deleteEvent(selectedEvent);
        const e = document.getElementById('eventContextMenu');
        e.classList.remove('visible');
      },
      () => {
        globalData.lastTextElement = selectedEvent.lastTextElement;
        lastPolygonFigure = selectedEvent.lastPolygonFigure;

        setEventSizeUpdated(selectedEvent.id, selectedEvent.signalId, selectedEvent.y, selectedEvent.h);
        setEventEditMode(false);
        setModalOpen(selectedEvent.signalId);
        setHoverElement(null);
      },
      () => {
        if (selectedEvent) deleteAllEventOfType(selectedEvent?.text, selectedEvent?.signalId);
        const e = document.getElementById('eventContextMenu');
        e.classList.remove('visible');
      },
    );
  }, [addEvent, selectedEvent]);

  // useEffect(() => {
  //   window.onbeforeunload = (e) => {
  //     if (globalData.eventsUpdated) saveData();
  //     return true;
  //   };
  // }, [saveData]);

  const deleteArtifact = useCallback(
    (del = false, id = null) => {
      if (del) {
        if (id === null) id = selectedArtifact?.id;
        if (!id) return;

        if (globalData.artifactsList[id]) {
          globalData.artifactsList[id].resizeElementFront?.dispose();
          globalData.artifactsList[id].resizeElementBack?.dispose();
          globalData.artifactsList[id].figure?.dispose();
        }

        const i = artifacts.findIndex((a) => a.id === id);
        const newArtifacts = artifacts.slice(0);
        newArtifacts[i].removed = true;
        globalData.eventsUpdated = true;
        if (globalData.zoomBandArtifacts[id]) {
          globalData.zoomBandArtifacts[id].dispose();
        }
        globalData.artifacts = newArtifacts;
        setArtifacts(newArtifacts);
        saveArtifact(newArtifacts[i]);
        // addHistoryEvent({
        //   type: 'artifact',
        //   prev: artifacts,
        //   next: newArtifacts,
        //   title: 'artifact delete',
        // });
      }
      setSelectedArtifact(null);
    },
    [artifacts, setArtifacts, selectedArtifact],
  );

  const updateChartsRange = useCallback(
    (ranges) => {
      globalData.chartRanges = { ...chartRanges, ...ranges };
      setChartRanges({ ...chartRanges, ...ranges });

      Object.keys(ranges).forEach((signalId) => {
        if (yAxisList[signalId]) {
          yAxisList[signalId].setInterval(ranges[signalId].min, ranges[signalId].max, true, true);
        }
        updateEventsHeight(parseInt(signalId), ranges[signalId]);
      });
      // if (Object.keys(ranges).find((id) => [834, 829].includes(parseInt(id)))) {
      //   //drawPulseValues({ ...chartRanges, ...ranges });
      // }
    },
    [chartRanges, updateEventsHeight],
  );

  const setChartGrid = useCallback((signalId, type) => {
    chartsList[signalId].getDefaultAxisX().setTickStrategy(AxisTickStrategies.DateTime, (dateTimeTicks) =>
      dateTimeTicks
        .setDateOrigin(dateOrigin)
        .setGreatTickStyle(emptyTick)
        .setMajorTickStyle((tickStyle) =>
          globalData.gridLines[signalId]
            ? tickStyle
                .setLabelFont((font) => font.setSize(0))
                .setTickLength(0)
                .setGridStrokeStyle(
                  new SolidLine({
                    thickness: 1,
                    fillStyle: new SolidFill({
                      color: ColorHEX('#aaaaaa50'),
                    }),
                  }),
                )
            : tickStyle
                .setGridStrokeStyle(emptyLine)
                .setLabelFont((font) => font.setSize(0))
                .setTickLength(0),
        )
        .setMinorTickStyle((tickStyle) =>
          globalData.gridLines[signalId]
            ? tickStyle
                .setLabelFont((font) => font.setSize(0))
                .setTickLength(0)
                .setGridStrokeStyle(
                  new SolidLine({
                    thickness: 1,
                    fillStyle: new SolidFill({
                      color: ColorHEX('#aaaaaa50'),
                    }),
                  }),
                )
            : tickStyle
                .setGridStrokeStyle(emptyLine)
                .setLabelFont((font) => font.setSize(0))
                .setTickLength(0),
        ),
    );

    yAxisList[signalId].setTickStrategy(AxisTickStrategies.Numeric, (tickStrategy) =>
      tickStrategy
        .setMajorFormattingFunction((tickPosition) => {
          return NoYLabelSignals.includes(type) ? '' : Math.round(tickPosition).toString();
        })
        .setMinorFormattingFunction((tickPosition) => {
          return NoYLabelSignals.includes(type) ? '' : Math.round(tickPosition).toString();
        })
        .setMajorTickStyle((tickStyle) =>
          globalData.gridLines[signalId]
            ? tickStyle.setGridStrokeStyle(
                new SolidLine({
                  thickness: 1,
                  fillStyle: new SolidFill({ color: ColorHEX('#aaaaaa50') }),
                }),
              )
            : tickStyle.setGridStrokeStyle(emptyLine),
        )
        .setMinorTickStyle((tickStyle) =>
          globalData.gridLines[signalId]
            ? tickStyle.setGridStrokeStyle(
                new SolidLine({
                  thickness: 1,
                  fillStyle: new SolidFill({ color: ColorHEX('#aaaaaa50') }),
                }),
              )
            : tickStyle.setGridStrokeStyle(emptyLine),
        ),
    );
  }, []);

  const revertAutoScore = useCallback(() => {
    setUpdatingEvents(true);
    const timer = setTimeout(() => {
      setUpdatingEvents(false);
    }, 10000);
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + globalData.token);
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    globalData.firstChartEvents.forEach((t, i) => {
      if (globalData.firstChartEvents[i].hiddenTemp) {
        globalData.firstChartEvents[i].hiddenTemp = undefined;
        globalData.firstChartEvents[i].show = true;
      }
    });

    fetch(`${host}/sleepstudy/api/reverttoautoscore?key=${studyId}`, requestOptions).then((result) => {
      let l = Object.keys(events).length;
      const data = {};
      let artifacts = [];
      Object.keys(events).forEach((signalId) => {
        const signal = studySignals.find((s) => s.SignalId.toString() === signalId);
        fetch(
          `${host}/sleepstudy/api/signalsleepevents?signalId=${signalId}&startTime=${timeIntervalString.StartTime}&endTime=${timeIntervalString.EndTime}`,
          requestOptions,
        )
          .then((response) => response.json())
          .then((result) => {
            l--;
            const eventData = [];
            result.forEach((currentValue, i3) => {
              if (currentValue.Type.includes('Artifact')) {
                artifacts.push({
                  start: new Date(currentValue.StartTime).getTime() - dateOriginTime,
                  end: new Date(currentValue.EndTime).getTime() - dateOriginTime,
                  signalId: currentValue.SignalId,
                  status: currentValue.IsAutoScored ? 'AutoScored' : 'Added',
                  id: artifactId,
                  ...currentValue,
                });
                artifactId++;
                return;
              }
              const id = eventId;
              eventId++;
              const searchName =
                currentValue.Type === 'Recovery' || currentValue.Type === 'Desaturation'
                  ? (signal.Type === 'Pulse' ? 'Pulse ' : 'Oximetry ') + currentValue.Type
                  : currentValue.Type;
              eventData.push({
                status: currentValue.IsAutoScored ? 'AutoScored' : 'Added',
                type: currentValue.Type,
                customCharacteristics: currentValue.CustomCharacteristics,
                startTimeMicro: new Date(currentValue.StartTime).getTime() - dateOriginTime,
                endTimeMicro: new Date(currentValue.EndTime).getTime() - dateOriginTime,
                customName:
                  signal.SignalId === globalData.pulseSignalId &&
                  (currentValue.Type === 'Recovery' || currentValue.Type === 'Desaturation') &&
                  currentValue.CustomCharacteristics['DPAR Event Type']
                    ? currentValue.CustomCharacteristics['DPAR Event Type']
                    : undefined,
                ...currentValue,
                searchName,
                id,
              });
            });
            data[signalId] = eventData;
            if (l < 1) {
              globalData.loadedEventsData = data;
              redrawCharts = true;
              globalData.eventsUpdated = true;
              setEvents(data);
              setArtifactsState(artifacts);
              setUpdatingEvents(false);
              clearTimeout(timer);
              drawAllEventsChart();
            }

            if (globalData.wakeSignalData)
              loadWakeSignal(globalData.wakeSignalData, () => {
                drawLSLabels();
              });
            //
          });
      });
    });
  }, [events, studyId, drawLSLabels]);

  const generatePdf = useCallback(async () => {
    setGeneratingPdf(true);
    try {
      if (globalData.eventsSaved) {
        $.post({
          url: '/pdf/' + globalData.analysisId,
          data: {
            preprocessing: false,
            reraPercent,
          },
        })
          .done((data) => {
            globalData.eventsSaved = false;
            downloadEmployeeData('/pdf/' + globalData.analysisId, globalData.guid + '.pdf');
            //document.getElementById('pdf_link').click();
            setTimeout(() => {
              downloadEmployeeData('/pdf/' + globalData.analysisId + '?multi=true', globalData.guid + '.multichannel.pdf');
              setGeneratingPdf(false);
            }, 3000);
          })
          .fail((e) => {
            setGeneratingPdf(false);
          });
      } else {
        downloadEmployeeData('/pdf/' + globalData.analysisId, globalData.guid + '.pdf');
        //document.getElementById('pdf_link').click();
        setTimeout(() => {
          downloadEmployeeData('/pdf/' + globalData.analysisId + '?multi=true', globalData.guid + '.multichannel.pdf');
          setGeneratingPdf(false);
        }, 3000);
      }
    } catch {
      setGeneratingPdf(false);
    }
  }, [setGeneratingPdf, reraPercent]);

  const unusedSignalsIds = signalIds && unusedSignals.map((i) => signalIds[i].SignalId);

  return (
    <>
      {loading && (
        <>
          <button className="btn btn-primary" type="button" disabled style={{ margin: '30px' }}>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
          </button>
        </>
      )}
      <div
        id="loading_spinner"
        style={{
          position: 'fixed',
          left: '48vw',
          top: '200px',
          zIndex: '10000',
          pointerEvents: 'none',
          display: 'none',
        }}
      >
        <Spin size="large" spinning={true}></Spin>
      </div>
      <div id="waterMarks"></div>
      <div id="eventHoverChartContainer" style={{ display: 'none' }} className="eventHover" role="textbox">
        <div id="eventHoverChart"></div>
        <div id="eventHoverText" className="eventHoverText"></div>
      </div>
      {!loading && (
        <div className={'ui_top ' + (generatingPdf ? 'blocked' : '')}>
          <div className="study_info">
            <div>Patient: {studyData?.PatientName} </div>
            <span style={{ fontSize: '13px', position: 'relative', top: '10px' }}>
              Study: {globalData.dateOriginTime && new Date(globalData.dateOriginTime).toLocaleDateString()}
            </span>
          </div>
          {false && (
            <div style={{ float: 'left' }}>
              <div className="redo_buttons">
                <Tooltip title={redoActive ? titles.redo : ''}>
                  <RedoOutlined
                    onClick={() => {
                      if (redoActive) {
                        redrawCharts = true;
                        redoEvent();
                      }
                    }}
                    className={redoActive ? '' : 'disabled'}
                  />
                </Tooltip>
                <Tooltip title={undoActive ? titles.undo : ''}>
                  <UndoOutlined
                    onClick={() => {
                      if (undoActive) {
                        redrawCharts = true;
                        undoEvent();
                      }
                    }}
                    className={undoActive ? '' : 'disabled'}
                  />
                </Tooltip>
              </div>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              position: 'relative',
              left: '-25px',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="ui_buttons">
                <span className="add_buttons">
                  <Button id="add_artifact_button" className="" onClick={() => addArtifactActivate()}>
                    Add artifact
                  </Button>
                </span>
                <span style={{ display: 'inline-block', marginRight: '10px' }}>
                  <div className="play_mode_button" menu={{ items: playItems }}>
                    <Button>
                      {speed
                        ? speedMultiplier !== 1
                          ? speedMultiplier > 0
                            ? `Fast forwarding at ${speedMultiplier}x`
                            : `Rewinding at ${speedMultiplier}x`
                          : 'Playing'
                        : 'Paused'}
                    </Button>
                  </div>
                </span>
                <CustomDropdown
                  props={{
                    eventVisibleSelectorClick,
                    eventVisibleItems,
                    visibleEvents,
                  }}
                />
                <Dropdown
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Space style={{ padding: '0 ' }}>
                        <Radio
                          checked={selectedTimeInterval.label === 'custom'}
                          onChange={(e) => {
                            const value = parseInt(document.getElementById('custom_time_interval').value);
                            setSelectedTimeTinterval({
                              label: 'custom',
                              value: value,
                            });
                            updateInterval(value * 60);
                          }}
                        />{' '}
                        <Input
                          onChange={(e) => {
                            setSelectedTimeTinterval({
                              label: 'custom',
                              value: e.target.value * 60,
                            });
                            updateInterval(e.target.value * 60);
                          }}
                          defaultValue={1}
                          value={selectedTimeInterval.value / 60}
                          id="custom_time_interval"
                          type="number"
                        />
                      </Space>
                    </>
                  )}
                  className="time_interval_mode_button"
                  menu={{ items: timeIntervalItems }}
                >
                  <Button>{minutesNumber < 1 ? '30 sec' : minutesNumber + ' min'}</Button>
                </Dropdown>
              </div>

              <div className="bottom_buttons">
                <span className="scoring_buttons">
                  {/* <Button id="save_scoring_button" className="disabled" onClick={() => saveData()}>
                Save Scoring
              </Button> 
              <Button
                id="save_scoring_report_button"
                className="disabled"
                onClick={() => saveData(studyData)}
              >
                Run Report
              </Button>*/}
                  <span
                    className={'btn btn-sm  ' + (generatingPdf ? 'btn-success' : 'btn-danger')}
                    onClick={(e) => {
                      generatePdf();
                    }}
                    title="Download PDF"
                  >
                    <FontAwesomeIcon className={'fa-fw ' + (generatingPdf ? 'fa-spin' : '')} icon={generatingPdf ? faSpinner : faFilePdf} />
                  </span>
                </span>
                <span className="search_form">
                  <span>
                    <Select
                      style={{ width: '180px', margin: '0 20px' }}
                      placeholder="Search"
                      value={
                        searchEvent
                          ? {
                              value: searchEvent,
                              label: searchEvent.replace(/([a-z])([A-Z])/g, '$1 $2'),
                            }
                          : null
                      }
                      listHeight={500}
                      virtual={false}
                      onChange={(value, option) => {
                        setSearchEvent(value);
                        setSelectedSearchEvent(null);
                      }}
                      options={
                        globalData.searchOptions ||
                        Object.keys(globalData.eventsTyped)
                          .sort()
                          .map((t) => ({
                            label: t,
                            value: t,
                          }))
                      }
                    />
                    {searchEvent && <span style={{ fontSize: '12px' }}>{globalData.eventsTyped[searchEvent]?.length || 0} events</span>}
                    {searchEvent && (
                      <span className="searchEventButtons">
                        <DoubleLeftOutlined
                          onClick={() => {
                            findEvent('first');
                          }}
                        />
                        <LeftOutlined
                          onClick={() => {
                            findEvent('prev');
                          }}
                        />
                        <RightOutlined
                          onClick={() => {
                            findEvent('next');
                          }}
                        />
                        <DoubleRightOutlined
                          onClick={() => {
                            findEvent('last');
                          }}
                        />
                      </span>
                    )}
                  </span>
                </span>
                <span className="rera_switch">
                  3%
                  <Switch
                    style={{
                      backgroundColor: reraPercent === 3 ? '#abbea9' : '#26c611',
                    }}
                    checked={reraPercent === 4}
                    onChange={(checked) => {
                      setReraPercent(checked ? 4 : 3);
                      globalData.eventsSaved = true;
                    }}
                  />
                  4%
                </span>
                <span
                  className="play_buttons"
                  onClick={(e) => {
                    const t = e.target.closest('span');
                    t?.classList.add('selected');
                    clickedPlayButton = [...t.parentNode.children].indexOf(t);
                  }}
                >
                  <Tooltip title="Go to previous hour">
                    <FastBackwardOutlined onClick={() => nextHour(false)} />
                  </Tooltip>
                  <Tooltip title="Rewind">
                    <BackwardOutlined className={clickedPlayButton % 4 === 1 && 'selected'} onClick={() => speed && setPlay(-1)} />
                  </Tooltip>
                  <Tooltip title="Previous page">
                    <VerticalRightOutlined
                      //className={clickedPlayButton === 2 && 'selected'}
                      onClick={() => nextPage(false)}
                    />
                  </Tooltip>
                  {speed ? (
                    <Tooltip title="Pause">
                      <PauseOutlined
                        className="active"
                        onClick={() => {
                          setPlay();
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Play">
                      <PlayCircleFilled
                        color="blue"
                        onClick={() => {
                          setPlay();
                        }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Next page">
                    <VerticalLeftOutlined
                      //className={clickedPlayButton === 4 && 'selected'}
                      onClick={() => nextPage()}
                    />
                  </Tooltip>
                  <Tooltip title="Fast forward">
                    <ForwardOutlined
                      className={clickedPlayButton % 4 === 1 && 'selected'}
                      onClick={() => {
                        speed && setPlay(1);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Go to next hour">
                    <FastForwardFilled onClick={() => nextHour()} />
                  </Tooltip>
                </span>
              </div>
            </div>
            {positionSignal && (
              <span className="bodyContainer disabled">
                <img id="body" src={bodyImage} alt="body" width={90} />
              </span>
            )}
          </div>
          <div className="revert_buttons">
            <div
              onClick={() => {
                if (window.confirm('Warning! This will remove all manual scores')) {
                  revertAutoScore();
                  removeHistory();
                }
              }}
              className="revert_button"
            >
              <img title="Revert to Autoscore" src={revertImage} />
            </div>
            <div
              className="save_preferences_button"
              onClick={() => {
                saveSignals(
                  events,
                  studyId,
                  viewConfigurationData,
                  studySignals,
                  chartRanges,
                  chartSizes,
                  globalData.chartColors,
                  zoomSignalId,
                  globalData.additionalLines,
                  unusedSignalsIds,
                  visibleEvents,
                );
              }}
            >
              <img title="Save Preferences" src={savePrefImage} />
            </div>
          </div>
        </div>
      )}
      <div
        onMouseEnter={() => {
          const listElement = document.getElementById('event_selector_list');
          listElement.classList.add('show2');
        }}
        onMouseLeave={() => {
          const listElement = document.getElementById('event_selector_list');
          listElement.classList.remove('show2');
        }}
        id="event_selector_list"
      >
        {eventVisibleItems && eventVisibleItems.map((item) => <div key={item.key}>{item.label}</div>)}
      </div>

      <div
        onClick={(e1) => {
          const e = document.getElementById('eventContextMenu');
          e?.classList.remove('visible');
          if (!eventWasClicked) {
            setSelectedEvent(null);
            eventWasClicked = false;
            globalData.eventPressed = null;
          }
        }}
        className={'chart ' + (generatingPdf ? 'blocked' : '')}
      >
        <ChartIcons
          signalsData={signalsData}
          setChartSettingsModal={setChartSettingsModal}
          signalPairs={signalPairs}
          loading={loading}
          chartRanges={chartRanges}
          chartColors={globalData.chartColors}
          chartSizes={chartSizes}
          unusedSignalsIds={unusedSignalsIds}
          addCharts={addCharts}
          allChartData={allChartData}
          selectedCharts={selectedCharts}
          rowHeight={rowHeight}
          switchCharts={switchCharts}
          getAutoScale={(s) => getAutoScale(s, zoomIntervalGlobal)}
          moveSourceChart={moveSourceChart}
          moveSecondChart={moveSecondChart}
          addSecondCharts={addSecondCharts}
          signalIds={signalIds}
          drawAllEventsChart={drawAllEventsChart}
          addFromDouble={addFromDouble}
          chartsDataUpdated={chartsDataUpdated}
          setChartRanges={(signalId, range) => {
            chartsDataUpdated = chartsDataUpdated + 1;
            updateChartsRange({ [signalId]: range });
            // if (globalData.eventTopCustom[signalId]) {
            //   redrawSignal(signalId, true);
            // }
          }}
          removeSecondSignal={(singalId) => {
            chartsDataUpdated = chartsDataUpdated + 1;
            signalChartsList[singalId] && signalChartsList[singalId].dispose();
          }}
          selectedChartIds={selectedChartIds}
          addHistoryEvent={addHistoryEvent}
          setSelectedCharts={setSelectedCharts}
          setZoomSignal={setZoomSignalId}
          zoomSignalId={zoomSignalId}
          additionalLines={globalData.additionalLines}
          setHistogramColor={setHistogramColor}
          updateChartsOrder={(charts) => {
            addHistoryEvent({
              type: 'chartOrder',
              prev: selectedCharts,
              next: charts,
              title: 'charts order change',
            });
            updateChartsOrder(charts);
          }}
        />

        <div
          onMouseLeave={() => setHoverElement(null)}
          onWheel={(e) => nextPage(e.deltaY > 0)}
          className={generatingPdf ? 'blocked' : ''}
          tabIndex={0}
          id="chartContainer"
        ></div>
      </div>
      {eventSize.start && interval.start && (
        <Modal
          title={eventEditMode ? 'Change Event' : 'Add Event'}
          centered
          open={Boolean(modalOpen)}
          onCancel={() => addEvent(true)}
          footer={[
            eventEditMode && (
              <Button danger key="delete" disabled={!selectedEvent} onClick={() => deleteEvent(selectedEvent)}>
                Delete
              </Button>
            ),
            <Button type="primary" key="ok" disabled={!selectedEvent} onClick={() => addEvent()}>
              Ok
            </Button>,
            <Button key="back" onClick={() => addEvent(true)}>
              Cancel
            </Button>,
          ]}
        >
          <Select
            placeholder="Select an event"
            optionFilterProp="children"
            onChange={onChange}
            bordered={false}
            style={{ backgroundColor: 'transparent' }}
            value={
              selectedEvent?.newText
                ? { label: selectedEvent.newText, value: selectedEvent.newText }
                : selectedEvent?.text
                ? { label: selectedEvent.text, value: selectedEvent.text }
                : null
            }
            dropdownMatchSelectWidth={false}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={
              modalOpen
                ? eventTypes[modalOpen]?.map((t) => ({
                    value: t,
                    label: t.replace('Small ', ''),
                  }))
                : []
            }
          />
          <div>
            <span className="eventDatePickerLabel">Chart top value</span>
            <span>
              <DatePicker
                defaultValue={dayjs(interval.start.getTime() + eventSize.start)}
                key={eventSize.start}
                onChange={(v, s) => {
                  newEventSize.start = v.$d;
                }}
                renderExtraFooter={() => 'extra footer'}
                showTime
              />
            </span>
          </div>
          <div className="">
            <span className="eventDatePickerLabel">Chart bottom value</span>
            <span>
              <DatePicker
                defaultValue={dayjs(interval.start.getTime() + eventSize.end)}
                key={eventSize.end}
                onChange={(v, s) => {
                  newEventSize.end = v.$d;
                }}
                showTime
              />
            </span>
          </div>
          {selectedEvent &&
            customEventFields(selectedEvent, selectedEvent?.newText || '', selectedEvent.customCharacteristics, (field, value) => {
              newEventSize.customFields = {
                ...newEventSize.customFields,
                [field]: value,
              };
            })}
        </Modal>
      )}
      <div id="sleepStage"></div>
      <div id="vertLines"></div>
      {selectedArtifact && (
        <Modal
          title="Change Artifact"
          centered
          open={Boolean(selectedArtifact)}
          onOk={() => {}}
          onCancel={() => {}}
          footer={[
            <Button key="delete" danger onClick={() => deleteArtifact(true)}>
              Delete
            </Button>,
            <Button key="back" onClick={() => deleteArtifact()}>
              Cancel
            </Button>,
          ]}
        ></Modal>
      )}

      <ChartSettingsModal
        chartColors={globalData.chartColors}
        signalPairs={signalPairs}
        chartSettingsModal={chartSettingsModal}
        setChartSettingsModal={setChartSettingsModal}
        additionalLines={globalData.additionalLines}
        selectedCharts={selectedCharts}
        setChartGrid={setChartGrid}
        drawLabels={drawPulseValues}
        setChartRanges={updateChartsRange}
        setChartColors={(signalId, color) => {
          globalData.chartColors = {
            ...globalData.chartColors,
            [signalId]: color,
          };
          lineSteps = getLineSteps(globalData.loadedEventsData, true);
          if (signalChartsList[signalId]) {
            if (lineSteps[signalId] && lineSteps[signalId].length > 1) {
              // lineSteps[signalId].sort((a, b) => a.value - b.value);
              signalChartsList[signalId].setStrokeStyle(
                new SolidLine({
                  thickness: 2,
                  fillStyle: new PalettedFill({
                    lookUpProperty: 'x',
                    lut: new LUT({
                      interpolate: false,
                      steps: lineSteps[signalId],
                    }),
                  }),
                }),
              );
            } else {
              signalChartsList[signalId].setStrokeStyle(
                new SolidLine({
                  thickness: 2,
                  fillStyle: new SolidFill({ color: ColorHEX(color) }),
                }),
              );
            }
          }
        }}
        switchCharts={switchCharts}
        addCharts={addCharts}
        chartRanges={chartRanges}
        gridLines={globalData.gridLines}
        chartSizes={chartSizes}
        setChartSizes={(sizes) => {
          setChartSizes(sizes);
          redrawCharts = true;
          chartsDataUpdated++;
        }}
        getAutoScale={(s) => getAutoScale(s, zoomIntervalGlobal)}
        updateChartsOrder={(charts) => {
          updateChartsOrder(charts);
        }}
        addHistoryEvent={addHistoryEvent}
      />

      <Modal
        footer={[
          <Button key="submit" type="primary" loading={false} onClick={() => setShowReportRunModal(false)}>
            Ok
          </Button>,
        ]}
        open={showReportRunModal}
        centered
      >
        Scoring is saved. Wait for the report loading ...
      </Modal>
      {eventContextElement}
    </>
  );
}

export default Charts;
